import React from 'react';
import { NavLink } from 'react-router-dom';
const courses = [
    {
        title: "Ardent Computech Pvt Ltd",
        address: "SDF Building, Module #132, Ground Floor, GP Block, Sector V, Bidhannagar, Kolkata",
        program: "Online Learning Program: Data Science with Artificial Intelligence",
        fees: "9200",
        link: "/contact"
    },
    {
        title: "The Chennai School Of Banking and Management",
        address: "No.10, MMH Complex, Natesan St, T. Nagar, Chennai, Tamil Nadu 600017, India",
        program: "Online Learning Program: Banking Exam Training",
        fees: "15000",
        link: "/contact"
    },
    {
        title: "MARS Training And Solutions",
        address: "Aspiration, Kidwai Apartment, 2nd floor, 40/1, Rafi Ahmed Kidwai Road, Ripon St, opposite T",
        program: "Data Analytics Training For 12 Months - Regular",
        fees: "18000",
        link: "/contact"
    },
    {
        title: "Tech Institute of Learning",
        address: "45A, Park Street, New Delhi",
        program: "Full Stack Web Development Bootcamp",
        fees: "15000",
        link: "/enroll"
    },
    {
        title: "AI & ML Academy",
        address: "12, MG Road, Bengaluru",
        program: "Advanced Machine Learning and AI Techniques",
        fees: "18000",
        link: "/apply"
    },
    {
        title: "National Institute of Digital Marketing",
        address: "5th Block, Koramangala, Bengaluru, Karnataka",
        program: "Digital Marketing Certification Course",
        fees: "12000",
        link: "/contact"
    },
    {
        title: "Python Data Science Academy",
        address: "Banjara Hills, Hyderabad, Telangana",
        program: "Python for Data Science and Machine Learning",
        fees: "14000",
        link: "/contact"
    },
    {
        title: "Cloud Computing Institute",
        address: "Hadapsar, Pune, Maharashtra",
        program: "AWS & Azure Cloud Computing Master Course",
        fees: "20000",
        link: "/contact"
    },
    {
        title: "Institute of Cyber Security",
        address: "Cyber Hub, Gurgaon, Haryana",
        program: "Certified Ethical Hacking and Cyber Security",
        fees: "22000",
        link: "/enroll"
    },
    {
        title: "Blockchain Academy India",
        address: "Jayanagar, Bengaluru, Karnataka",
        program: "Blockchain Development and Cryptocurrency Training",
        fees: "25000",
        link: "/apply"
    }
];


const Institutes = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>List of Top Institutes in India based on 2025 Rankings</h2>
                            <p>India is home to some of the world’s most prestigious institutes, offering top-quality education across various fields.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mb-4">
                <div className="row">
                    {courses.map((course, index) => (
                        <div className="col-12" key={index}>
                            <div className="card mt-3">
                                <div className="row p-3">
                                    <div className="col-lg-7">
                                        <div className='text-dark'>
                                            <h4>{course.title}</h4>
                                            <p>{course.address}</p>
                                            <h6>{course.program}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div>
                                            <h4>Total Fees: <strong className='text-success'>₹{course.fees}/-</strong></h4>
                                            <div className='pt-4'>
                                                <NavLink to={course.link} className="mybtn p-2 rounded-pill">Request a Callback</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Institutes;
