import React, { useState } from "react";
import axios from "axios";
import config from "../config/config";

const AddCollegePage = () => {
  const [formData, setFormData] = useState({
    collegeName: "",
    city: "",
    fee: "",
    placementavg: "",
    placementhig: "",
    bannerText: "",
    bannersubText: "",
    heading: "",
    subHeading1: "",
    subHeading2: "",
    coursesDeatils: [
      {
        courses: "",
        fees: "",
        eligibility: "",
        applicationDate: "",
      },
    ], // updated to match schema
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCourseChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCourses = [...formData.coursesDeatils];
    updatedCourses[index][name] = value;
    setFormData({ ...formData, coursesDeatils: updatedCourses });
  };

  const addCourse = () => {
    setFormData({
      ...formData,
      coursesDeatils: [
        ...formData.coursesDeatils,
        { courses: "", fees: "", eligibility: "", applicationDate: "" },
      ],
    });
  };

  const removeCourse = (index) => {
    const updatedCourses = formData.coursesDeatils.filter((_, i) => i !== index);
    setFormData({ ...formData, coursesDeatils: updatedCourses });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.API_BASE_URL}/api/admin/create_collegespage`, formData);
      alert("College page added successfully!");
      setFormData({
        collegeName: "",
        fee: "",
        placementavg: "",
        placementhig: "",
        bannerText: "",
        bannersubText: "",
        heading: "",
        subHeading1: "",
        subHeading2: "",
        coursesDeatils: [],
      });
    } catch (error) {
      console.error("Error adding college page:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Add College Page</h2>
      <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
        <div className="mb-3">
          <label className="form-label">College Name:</label>
          <input
            type="text"
            className="form-control"
            name="collegeName"
            value={formData.collegeName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">City Name:</label>
          <input
            type="text"
            className="form-control"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Fee:</label>
          <input
            type="text"
            className="form-control"
            name="fee"
            value={formData.fee}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Placement Average:</label>
          <input
            type="text"
            className="form-control"
            name="placementavg"
            value={formData.placement}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Placement Highest:</label>
          <input
            type="text"
            className="form-control"
            name="placementhig"
            value={formData.placement}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Courses:</label>
          {formData.coursesDeatils.map((course, index) => (
            <div key={index} className="d-flex mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Course Name"
                name="courses"
                value={course.courses}
                onChange={(e) => handleCourseChange(index, e)}
                required
              />
              <input
                type="text"
                className="form-control"
                placeholder="Fees"
                name="fees"
                value={course.fees}
                onChange={(e) => handleCourseChange(index, e)}
                required
              />
              <input
                type="text"
                className="form-control"
                placeholder="Eligibility"
                name="eligibility"
                value={course.eligibility}
                onChange={(e) => handleCourseChange(index, e)}
                required
              />
              <input
                type="text"
                className="form-control"
                placeholder="Application Date"
                name="applicationDate"
                value={course.applicationDate}
                onChange={(e) => handleCourseChange(index, e)}
                required
              />
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={() => removeCourse(index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button type="button" className="btn btn-success" onClick={addCourse}>
            Add Course
          </button>
        </div>

        <div className="mb-3">
          <label className="form-label">Banner Text:</label>
          <input
            type="text"
            className="form-control"
            name="bannerText"
            value={formData.bannerText}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Banner Sub Text:</label>
          <input
            type="text"
            className="form-control"
            name="bannersubText"
            value={formData.bannersubText}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Heading:</label>
          <input
            type="text"
            className="form-control"
            name="heading"
            value={formData.heading}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Sub Heading 1:</label>
          <input
            type="text"
            className="form-control"
            name="subHeading1"
            value={formData.subHeading1}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Sub Heading 2:</label>
          <input
            type="text"
            className="form-control"
            name="subHeading2"
            value={formData.subHeading2}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Add College Page
        </button>
      </form>
    </div>
  );
};

export default AddCollegePage;
