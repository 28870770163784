import React, { useRef } from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';

import img1 from '../Images/New Delhi.svg'
import img2 from '../Images/Bangalore.svg'
import img3 from '../Images/Hyderabad.svg'
import img4 from '../Images/Pune.svg'
import img5 from '../Images/Mumbai.svg'
import img6 from '../Images/Chennai.svg'
import img7 from '../Images/Kolkata.svg'
import img8 from '../Images/Pune.svg'
import img9 from '../Images/Noida.svg'



import call_of_img from '../Images/callof_action.webp'


const studyGoals = [
    {
        img: img1,
        heading: "Delhi NCR"
    },
    {
        img: img2,
        heading: "Bangalore"
    },
    {
        img: img3,
        heading: "Hyderabad"
    },
    {
        img: img4,
        heading: "Pune"
    },
    {
        img: img5,
        heading: "Mumbai"
    },

    {
        img: img6,
        heading: "Chennai"
    },

    {
        img: img7,
        heading: "Kolkata"
    },


    {
        img: img8,
        heading: "Bhopal"
    },

    {
        img: img9,
        heading: "Noida"
    },
];




const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6  // Updated to 7 items for extra-large screens
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7  // Updated to show 7 items on desktop
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Topstudyplaces = () => {
    const carouselRef = useRef(null);

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    return (

        <>
            <div className="container topcontainer">
                <h1 className='exploretitle'>Top Study Places</h1>
                <Carousel ref={carouselRef} responsive={responsive} arrows={false}>
                    {studyGoals.map(({ img, heading, link }, index) => (
                        <Link to='/TopStudy-colleges' key={index} className="text-decoration-none">
                            <div className="border w-75 rounded-3 pb-3 text-center cursor-pointer">
                                <img src={img} alt="iconimg" className="mx-3 iconimg w-75" />
                                <h4 className="text-dark fs-6 fw-semibold mt-2">{heading}</h4>
                            </div>
                        </Link>
                    ))}
                </Carousel>


                {/* Custom Navigation Buttons */}
                <button className="fa fa-angle-left places_custom-prev" onClick={handlePrev} aria-label="Previous"></button>
                <button className="fa fa-angle-right places_custom-next" onClick={handleNext} aria-label="Next"></button>
            </div>



            {/* Call of section img only  */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img src={call_of_img} alt="" className='call_of_img' />
                    </div>
                </div>
            </div>



        </>

    );
}

export default Topstudyplaces;
