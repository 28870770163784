import { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Popupform from '../../Components/Popupform/Popupform'

// leftside img 

import leftimg from '../../Components/Images/innerpageimg.png'

import img1 from '../../Components/Images/1 (1).webp'
import img2 from '../../Components/Images/1 (1).svg'
import img3 from '../../Components/Images/1 (2).webp'

// table imges 

// import col_logo from '../../Components/Images/col_logo1.webp'
// import col_logo2 from '../../Components/Images/col_logo2.webp'
// import col_logo3 from '../../Components/Images/col_logo3.webp'
// import col_logo4 from '../../Components/Images/col_logo4.webp'
// import axios from "axios";
// import config from "../../config/config";
import CollageTable from "./CollageTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";

const Collegemainpage = () => {

    // const tableData = [
    //     {
    //         rank: 1,
    //         logo: col_logo,
    //         college: "IIT Bombay - Indian Institute of Technology - IITB, Mumbai",
    //         subheading: "Mumbai, Maharashtra | AICTE, UGC Approved",

    //         fees: "2,30,700",
    //         coursename: "BE/B.Tech - 1st Year Fees",

    //         placement: "23,50,000",
    //         placementname: 'Average Package',

    //         placement_to: "1,00,00,000",
    //         placementname_to: 'Higest Package'
    //     },

    //     {
    //         rank: 2,
    //         logo: col_logo4,
    //         college: "IIT Delhi - Indian Institute of Technology IITD, New Delhi",
    //         subheading: "New Delhi, Delhi NCR ",

    //         fees: "2,28,650",
    //         coursename: "BE/B.Tech - 1st Year Fees",

    //         placement: "25,80,000",
    //         placementname: 'Average Package',

    //         placement_to: "2,00,00,000",
    //         placementname_to: 'Higest Package'
    //     },


    //     {
    //         rank: 3,
    //         logo: col_logo3,
    //         college: "IIT Madras - Indian Institute of [IITM]Chennai",
    //         subheading: "Chennai, Tamil Nadu |AICTE Approved",

    //         fees: "2,42,000",
    //         coursename: "BE/B.Tech - 1st Year Fees",

    //         placement: "21,48,000",
    //         placementname: 'Average Package',

    //         placement_to: "1,98,00,000",
    //         placementname_to: 'Higest Package'
    //     },

    //     {
    //         rank: 4,
    //         logo: col_logo4,
    //         college: "IIT Kanpur - Indian Institute of Technology - [IITK],Kanpur",
    //         subheading: "Kanpur, Uttar Pradesh |UGC Approved",

    //         fees: "2,29,970",
    //         coursename: "BE/B.Tech - 1st Year Fees",

    //         placement: "21,48,000",
    //         placementname: 'Average Package',

    //         placement_to: "1,90,00,000",
    //         placementname_to: 'Higest Package'
    //     },
    //     {
    //         rank: 5,
    //         logo: col_logo4,
    //         college: "IIT Bombay - Indian Institute of Technology - IITB, Mumbai",
    //         subheading: "Mumbai, Maharashtra | AICTE, UGC Approved",
    //         fees: "2,28,650",
    //         coursename: "BE/B.Tech - 1st Year Fees",

    //         placement: "25,80,000",
    //         placementname: 'Average Package',

    //         placement_to: "2,00,00,000",
    //         placementname_to: 'Higest Package'
    //     },


    // ];


    const urlid = useParams()
    const id = urlid.id

    const [singlepage, setSinglepage] = useState()

    useEffect(() => {
        const fetchSingleCoursepage = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/getSingle_collegespage/${id}`
                );
                const fetchedData = response.data;
                setSinglepage(fetchedData);
                console.log("Fetched Data:", fetchedData); // Debugging log
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchSingleCoursepage();
    }, []);
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner main_college_banner'>
                            <h2>{singlepage?.bannerHeading}</h2>
                            <p>{singlepage?.bannerText}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid firstrow" >
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                            <span>  <h6 className='text-secondary mt-1'>BE/B.Tech Colleges</h6></span>
                        </div>

                        <p className='toph3 text-start mx-2'>
                            Top BTech Colleges in India Based on 2024 Ranking
                        </p>

                    </div>
                </div>


                {/* img row  */}
                <div className="row pt-4 justify-content-center">
                    <div className="col-lg-3">
                        <div>
                            <img src={img1} alt="" className='img-fluid mb-3 ' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img2} alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img3} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            {/* courses table  */}
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col">
                        <div className='text-center'>
                            <h2 className='exploretitle '>{singlepage?.tableHeading}</h2>
                            <p className='explore_para'>{singlepage?.tableText}</p>
                        </div>
                    </div>
                </div>

            </div>

            <CollageTable />


            <div className="container main_page_container">
                <div className="row">
                    <div className="col">
                        <div className='text-center'>
                            <h2 className='exploretitle '>{singlepage?.heading} </h2>
                            <p className='explore_para'>{singlepage?.subHeading}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* text and img section  */}
            <div className="container">
                <div className="row">
                    {/* img col  */}
                    <div className="col-lg-6">
                        <div className='mt-5 pt-2'>
                            <img src={leftimg} alt="" className='leftimg' />
                        </div>
                    </div>


                    {/* text col  */}
                    <div className="col-lg-6">
                        <div>
                            <div className='exploretitle '>
                                <h1 className='fw-bold fs-1 mt-5'>{singlepage?.imgHeading}</h1>
                            </div>
                            <p className=' explore_para text-start mt-4'>{singlepage?.imgpara1}</p>

                            <p className="explore_para text-start">{singlepage?.imgpara2}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 pt-5">

                <Popupform />
            </div>

        </div>

    )
}

export default Collegemainpage
