import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Admin.css";
import logoimg from '../Components/Images/GET-FAST-EDUCATION-LOGO-1.png'

const Admin_Navbar = () => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  }

  const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("token-info");
        localStorage.removeItem("userId");
        navigate("/admin");
    };

  return (
    <>
      <nav className='admin_nav'>
        <div>
          <img src={logoimg} style={{ maxWidth: '150px' }} className='img_size' alt="" />
        </div>
        <div>
          <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
            <li><Link className='active' to="/admin/dashboard">Home</Link></li>
            <li><Link to="/admin/collegepage">College Page</Link></li>
            <li><Link to="/admin/navcoursepagelist">Nav Course Page</Link></li>
            {/* <li><Link to="/admin/college">College</Link></li>
            <li><Link to="/admin/course">Course</Link></li> */}
            <li><Link to="/admin/stream">Stream</Link></li>
            <li><Link to="#"><button className="btn btn-primary rounded-pill px-3" onClick={logout} style={{ marginRight: "0px", color: "white" }}>Logout</button></Link></li>
          </ul>
        </div>
        <div id="mobile" onClick={handleClick}>
          <i id='bar' className={clicked ? 'fa fa-times' : 'fa fa-bars'}></i>
        </div>
      </nav>
    </>
  );
};

export default Admin_Navbar
