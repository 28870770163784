import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import Popupform from '../../Popupform/Popupform';


const tableData = [
    {
        coursename: "B.Tech Computer Science Engineering - IIT Delhi",
        fees: "₹2,28,650 (1st Year Fees)",
        eligibility: "JEE-Advanced (Cutoff: 417)",
        applicationDate: "April - May 2024",
    },
    {
        coursename: "MBA - FMS Delhi",
        fees: "₹1,16,000 (1st Year Fees)",
        eligibility: "CAT (Cutoff: 98)",
        applicationDate: "December 2024",
    },
    {
        coursename: "B.Tech Internet of Things - NSUT",
        fees: "₹2,40,000 (1st Year Fees)",
        eligibility: "JEE-Main (Cutoff: 115477)",
        applicationDate: "April - May 2024",
    },
    {
        coursename: "B.Tech Computer Science Engineering - NIT Delhi",
        fees: "₹1,61,600 (1st Year Fees)",
        eligibility: "JEE-Main (Cutoff: 8018)",
        applicationDate: "April - May 2024",
    }
];

const Innerpagetopstudy = () => {

    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>Explore the Best Study Destinations in India </h2>
                            <p>Looking for the perfect city to pursue your dreams? Explore the top study destinations in India, known for their <br /> prestigious universities, world-class faculty, and excellent career opportunities.</p>
                        </div>
                    </div>
                </div>
            </div>



            {/* Table  */}
            <div className="container mt-5">
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#e5edff" }}>
                                        <th>Course</th>
                                        <th>Fees</th>
                                        <th>Eligibility</th>
                                        <th>Application Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-3 fw-semibold p-3">
                                                <NavLink to={`/branch/${index}`} className="text-decoration-none text-dark">
                                                    {item.coursename}
                                                </NavLink>
                                            </td>
                                            <td className="text-success">{item.fees}</td>
                                            <td>{item.eligibility}</td>
                                            <td>{item.applicationDate}</td>
                                            <td>
                                                <NavLink to="#" className="text-decoration-none" onClick={handleOpenPopup}>
                                                    Apply Now <i className="fa fa-angle-double-right"></i>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Popup Modal */}
                        {showPopup && (
                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <button className="close-btn" onClick={handleClosePopup}>
                                        &times;
                                    </button>
                                    <Popupform />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>





        </div>
    )
}

export default Innerpagetopstudy
