import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import Popupform from '../../Components/Popupform/Popupform';

const CollageTable = () => {

    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent navigation
        setShowPopup(true);
        document.body.style.overflow = "hidden"; // Disable background scrolling
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        document.body.style.overflow = "auto"; // Enable background scrolling
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);


    useEffect(() => {
        if (showPopup) {
            document.body.classList.add("popup-open");
        } else {
            document.body.classList.remove("popup-open");
        }
    }, [showPopup]);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_collegespage`
                );
                const fetchedData = response.data.collegePages || [];
                setTableData(fetchedData);
                console.log("fetchedData", fetchedData); // Log for debugging
            } catch (error) {
                console.error("Error fetching college data:", error);
            }
        };

        fetchCollegeData();
    }, []);

    return (
        // <div>CollageTable</div>
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr style={{ backgroundColor: '#e5edff' }}>
                                    <th scope="col">CD Rank</th>
                                    <th scope="col">Colleges</th>
                                    <th scope="col">Course Fees</th>
                                    <th scope="col">Placement</th>
                                    <th scope="col">Ranking</th>
                                </tr>
                            </thead>
                            <tbody className="tablebody">
                                {tableData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center fw-semibold"> # {item.rank}</td>
                                        {/* College Data TD */}
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <img src={item.logo} alt="" className="img-fluid" style={{ maxWidth: "40px", height: "auto" }} />
                                                </div>
                                                <div>
                                                    <NavLink to={`/college/${item._id}`} className="text-decoration-none text-dark fs-6 fw-bold">
                                                        {item.collegeName}
                                                    </NavLink>
                                                    <div>
                                                        <small>Mumbai, Maharashtra |AICTE, UGC Approved </small>
                                                    </div>
                                                    <div>
                                                        <small className="fs-6">{item.subheading}</small>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <NavLink to="" className="mt-2 d-block text-decoration-none" onClick={handleOpenPopup}>
                                                            Apply Now <i className="fa fa-angle-double-right"></i>
                                                        </NavLink>

                                                        <NavLink className="nav-link" style={{ color: '#ff7900' }}>Download Boucher</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        {/* Fees TD */}
                                        <td>
                                            <div>
                                                <h5> &#8377; {item.fee}</h5>
                                            </div>
                                            <small>{item.coursename}</small>
                                        </td>

                                        {/* Placement TD */}
                                        <td>
                                            <div>
                                                <span> &#8377; {item.placementavg} </span>
                                                <div>
                                                    <small>Average Package</small>
                                                </div>
                                            </div>
                                            <div>
                                                <span> &#8377; {item.placementhig} </span>
                                                <div className=''>
                                                    <small>Higest Package</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            #1th/762 in India
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    {/* Popup Modal */}
                    {showPopup && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <button className="close-btn" onClick={handleClosePopup}>
                                    &times;
                                </button>
                                <Popupform />
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </div >
    )
}

export default CollageTable




