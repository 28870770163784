import React from 'react'
import { Link } from 'react-router-dom';
import img1 from '../../../Images/btech.png'
import img2 from '../../../Images/mtech2.png'
import { NavLink } from 'react-bootstrap';

const Mbbs = () => {
    return (
        <div>

<div className="container-fluid">
        <div className="row">
          <div className="col p-0 m-0">
            <div className='background_banner'>
              <h2>Empowering Your Educational Journey </h2>
              <p>At Get Fast Education, we are committed to guiding students toward academic success and career excellence. <br /> With expert educators and personalized support, we help you unlock a world of opportunities.</p>
            </div>
          </div>
        </div>
      </div>

            <div className="container-fluid firstrow">
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                              <h6 className='text-secondary mt-1'>MBBS Colleges</h6>
                        </div>
                        <p className='fw-semibold toph3 text-start fs-4 mx-2'>
                            Top MBBS Colleges in Banglore Based on 2024 Ranking
                        </p>
                    </div>
                </div>


                {/* img row  */}
                <div>
                    <div className="row p-4">
                        <div className="col-lg-2"></div>

                        <div className="col-lg-4">
                            <div>
                                <img src={img1} alt="" className='img-fluid mb-3 ' />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <img src={img2} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>


                    <div>
                        <div className="row bg-light">
                            <div className="col p-5">
                                <div className="card firstrow border-0  p-3 ">
                                    <div>
                                        <h6 className='fw-semibold'>Latest Notifications</h6>
                                        <p className='text-danger'>28 July, 2024 :  <NavLink>NEET UG 2024 Revised Cutoff Declared, Check Category-wise Cutoffs Here</NavLink> </p>
                                        <p className='text-danger'>25 July, 2024 : <NavLink>NEET UG 2024 Revised Scorecard Released; Check Direct Link Here</NavLink> </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


        </div>
    )
}

export default Mbbs
