import React from 'react'
import Hslide from '../../Hslide/Hslide'
import Exploreprog from '../../Explore/Exploreprog'
import Topcourses from '../../Topcourses/Topcourses'
import Topcolleges from '../../Topcolleges/Topcolleges'
import Topstudyplaces from '../../TopstudyPlaces/Topstudyplaces'

const Engineering = () => {
    return (
        <div>
            <Hslide />
            <Exploreprog />
            <Topcourses />
            <Topcolleges />
            <Topstudyplaces />
        </div>
    )
}

export default Engineering
