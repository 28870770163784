import React from 'react'
import Cardslider from '../../Components/Cardslider/Cardslider'
import Program from '../../Components/Program/Program'
import Colleges from '../../Components/Colleges/Colleges'
// import Testimonial from '../../Components/Testimonials/Testimonial'
// import Whychose from '../../Components/Whychoseus/Whychose'
// import Expert from '../../Components/Expert/Expert'
import Hslide from '../../Components/Hslide/Hslide'
import Faq from '../../Components/Faq/Faq'
import Topcolleges from '../../Components/Topcolleges/Topcolleges'
import Collegeranking from '../../Components/Collegranking/Collegeranking'
import Exploreprog from '../../Components/Explore/Exploreprog'
import Topstudyplaces from '../../Components/TopstudyPlaces/Topstudyplaces'
import Admission from '../../Components/Admission/Admission'
// import Topcourses from '../../Components/Topcourses/Topcourses'
// import Cbse from '../../Components/Cbse/Cbse'
import Examcomp from '../../Components/Examcomp/Examcomp'


const Home = () => {
  return (
    <div>

      {/* all Home page component import here  */}
      <Hslide />
      <Cardslider />
      <Exploreprog />
      <Topcolleges />
      <Colleges />
      <Collegeranking />
      <Topstudyplaces />
      <Program />
      {/* ----------------- */}
      {/* <Cbse /> */}
      {/* ------------- */}
      <Examcomp />
      <Admission />
      <Faq />

      {/* <Topcourses /> */}
      {/* (why choose section in this component colleges) */}
      {/* <Testimonial /> */}
      {/* IN Why choose us  */}
      {/* Know About Get Fast Education section  */}
      {/* From Our Post. section */}
      {/* Why Choose Us? Section */}
      {/* <Whychose /> */}
      {/* <Expert /> */}





    </div>
  )
}

export default Home
