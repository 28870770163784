import React from 'react'



import img1 from '../Images/col_icon1.webp'
import img2 from '../Images/col_icon2.webp'
import img3 from '../Images/col_icon3.webp'
import img4 from '../Images/col_icon4.webp'
import img5 from '../Images/col_icon5.webp'
import img6 from '../Images/col_icon6.webp'

import call_icon from '../Images/graphicon.webp'
import call_icon2 from '../Images/graphicon2.webp'
import { Link } from 'react-router-dom'



const tabcardData = [
    { id: 1, courses: ["Indiatoday", "The Week", "NIRF", "Outlook", "IIRF",] },
    { id: 2, courses: ["TOI", "NIRF", "NIRF Innovation"] },
    { id: 3, courses: ["THE", "QS",] },

];



const Collegeranking = () => {
    return (
        <div>
            {/* call of action  */}
            <div className="container mt-5 p-4" style={{ backgroundColor: '#fce9d2' }}>
                <div className="row align-items-center">
                    <div className="col-lg-10">
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between pt-4">
                            <h4 className="mx-5 pt-1 callof_text text-center text-lg-start">
                                Know your chances of Admission
                            </h4>

                            <Link to="/contact"
                                className="mybtn nav-link text-light fw-bold px-4 py-2 mt-3 mt-lg-0"
                                style={{ backgroundColor: '#ff7700', borderRadius: '5px' }}>
                                Start Now
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-2 text-center text-lg-end">
                        <img src={call_icon} alt="" className="w-50" />
                    </div>
                </div>
            </div>

            {/* call of action end  */}


            <div className="container mt-3 pt-3">
                <h1 className='exploretitle'>
                    College Ranking 2025
                </h1>



                <div className="tabs-container">
                    {tabcardData.map((card) => (
                        <div key={card.id} className="tab-item">
                            <ul className="exp_tab_ul_li">
                                {card.courses.map((course, index) => (
                                    <span key={index} className="exp_tab_li">
                                        {course}
                                    </span>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className="row">
                    <div className="col">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">College</th>
                                    <th scope="col">Ranking</th>
                                    <th scope="col">Streams</th>
                                </tr>
                            </thead>
                            <tbody className='tablebody'>
                                <tr>
                                    <td className='fw-bold'>
                                        <Link to="/College-Ranking" className='nav-link'>
                                            <img src={img1} className='me-4' alt="" />Jawaharlal Nehru University - [JNU], New Delhi
                                        </Link>
                                    </td>
                                    <td> 1 out of 44</td>
                                    <td>Overall</td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img2} className='me-4' alt="" />Symbiosis International University - [SIU], Pune</td>
                                    <td>1 out of 73</td>
                                    <td>Overall</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>
                                        <img src={img3} className='me-4' alt="" />Amity University, Noida</td>
                                    <td>2 out of 73</td>
                                    <td>Overall</td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img4} className='me-4' alt="" />Delhi University - [DU], New Delhi</td>
                                    <td>2 out of 44</td>
                                    <td>Overall</td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img5} className='me-4' alt="" />Aligarh Muslim University - [AMU], Aligarh</td>
                                    <td>3 out of 44</td>
                                    <td>Overall</td>
                                </tr>

                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img6} className='me-4' alt="" />K L University - [KLU], Guntur</td>
                                    <td>3 out of 73</td>
                                    <td>Overall</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>





            {/* call of action  */}
            <div className="container mt-5 p-4" style={{ backgroundColor: '#fce9d2' }}>
                <div className="row align-items-center">
                    <div className="col-lg-10">
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between pt-4">
                            <h4 className="mx-5 pt-1 callof_text text-center text-lg-start">
                                Subscribe For Regular Alerts
                            </h4>

                            <Link to="/contact"
                                className="mybtn nav-link text-light fw-bold px-4 py-2 mt-2 mt-lg-0"
                                style={{ backgroundColor: '#ff7700', borderRadius: '5px' }}>
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-2 text-center text-lg-end">
                        <img src={call_icon2} alt="" className="w-75" />
                    </div>
                </div>
            </div>

            {/* call of action end  */}



        </div>
    )
}

export default Collegeranking
