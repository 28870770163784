import React from 'react'


import img1 from '../Images/exploreicon (1).png'
import img2 from '../Images/exploreicon (6).png'
import img3 from '../Images/exploreicon (3).png'
import img4 from '../Images/exploreicon (4).png'
import img5 from '../Images/exploreicon (5).png'
import { NavLink } from 'react-router-dom'
const cardData = [
    {
        id: 1,
        imgSrc: img1,
        title: "Ranking",
        text: "College ranked based on real data",
        rankings: [
            "Indiatoday - 1737",
            "Outlook - 1402",
            "IIRF - 1551"
        ],
        link: '/CollegRanking',
        linkText: "Top Ranked Colleges in India"
    },
    {
        id: 2,
        imgSrc: img2,
        title: "Find Colleges",
        text: "Discover colleges via preferences",
        rankings: [
            "Best MBA colleges in India",
            "Best BTech colleges in India",
        ],
        link: "#",
        linkText: "Discover Top Colleges in India"
    },
    {
        id: 3,
        imgSrc: img3,
        title: "Exam",
        text: "Know more about your exams",
        rankings: [
            " B.Com",
            "BBA/BMS",
            " B.Sc (Nursing)",
            "MCA",
            "M.Tech"
        ],
        link: "/Exam",
        linkText: "Check All Entrance Exams in India"
    },

    {
        id: 4,
        imgSrc: img4,
        title: "Course Finder",
        text: "Discover top courses in Indian Colleges 2025",
        rankings: [
            "  BE/B.Tech - 1167",
            "ME/M.Tech - 1221",
            " MBA/PGDM - 1140"
        ],
        link: '/TopCourse',
        linkText: "Get Top Courses in Indian Colleges"
    },


    {
        id: 5,
        imgSrc: img5,
        title: "College Predictor",
        text: "Know your college admission chances",
        rankings: [
            "NEET",
            "JEE Advanced",
            "JEE Main",
            "CAT",
            "MAt",
            "GATE"
        ],
        link: '/college-predictor',
        linkText: "Find Where you may get Admission"
    },


    {
        id: 5,
        imgSrc: img5,
        title: "College Compair",
        text: "Compare on the basis of rank,fees, etc.",
        rankings: [
            "IIT Madras",
            "IIT Delhi",
            "IIT Bombay",
            "IIT Roorkee",
        ],
        link: "#",
        linkText: "Compair Colleges"
    }


];



const tabcardData = [
    { id: 1, courses: ["B.Tech", "M.Tech", "MBA", "MCA", "BCA",] },
    { id: 2, courses: ["M.Com", "B.Com", "BA"] },
    { id: 3, courses: ["M.Sc", "BBA/BMS", "B.Sc (Nursing)"] },

];
const Exploreprog = () => {
    return (
        <div>


            <div className="container mt-5">
                <div className="row">
                    <h1 className='exploretitle mb-4 '>Explore Programs</h1>
                    {/* TAB buttons div */}
                    <div className="tabs-container">
                        {tabcardData.map((card) => (
                            <div key={card.id} className="tab-item">
                                <ul className="exp_tab_ul_li">
                                    {card.courses.map((course, index) => (
                                        <span key={index} className="exp_tab_li">
                                            {course}
                                        </span>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>


                    {cardData.map((card) => (
                        <div key={card.id} className="col-md-4">
                            <div className="card explore_crd mb-3">

                                <div className=" explore_crd_body">
                                    <div>
                                        <h5 className="explore_crd_title fw-bold">{card.title}</h5>
                                        <p className="explore_crd_text">{card.text}</p>
                                    </div>
                                    <div>
                                        <img className="explore_crd_img" src={card.imgSrc} alt="" />
                                    </div>


                                </div>
                                <ul className=" exp_ul_li">
                                    {card.rankings.map((rank, index) => (
                                        <span key={index} className="exp_li">
                                            {rank}
                                        </span>
                                    ))}
                                </ul>
                                <div className="p-3">
                                    <NavLink to={card.link} className="explore_crd_link">
                                        {card.linkText} <i className="fa fa-angle-double-right ms-1"></i>
                                    </NavLink>
                                </div>
                            </div>


                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export default Exploreprog
