import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../config/config';

const StreamList = () => {
  const [stream, setStream] = useState([]);

  useEffect(() => {
    const fetchStreamData = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/api/admin/getall_streams`
        );
        const fetchedData = response.data || [];
        setStream(fetchedData);
        console.log("fetchedData", fetchedData); // Log for debugging
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchStreamData();
  }, []);

  return (
    <div>
      <div className="container mt-5 pt-5">
        <div className="row m-2">
          <div className="col-6">
            <h1>Stream List</h1>
          </div>
          <div className="col-6">
            <Link to="/admin/add_stream" className="btn btn-success float-end">
              Add Stream
            </Link>
          </div>
        </div>
        <div className="row p-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Collage Name</th>
                <th>Course Name</th>
                <th>Streams Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {stream.map((item) => (
                <tr key={item._id}>
                  <td>{item.collegeName}</td>
                  <td>{item.courseName}</td>
                  <td>{item.streamsName}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      // onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default StreamList