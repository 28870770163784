import React from 'react'
import { NavLink } from 'react-router-dom';
import '../pages.css'



// images imports 
import img1 from './blog1.jpg';
import img2 from './blog3.jpg';
import img3 from './blog4.jpg';
import img4 from './bolg2.jpg';
import img5 from './blog4.webp';

const Blogs = [
    {
        name: 'Is MCA Better Than B.Tech CSE?',
        para: 'When choosing a career path in the ever-evolving field of technology, many students find themselves at a crossroads: Should they pursue a Master of Computer Applications (MCA) or a Bachelor of...',
        link: '/',
        img: img1
    },
    {
        name: 'Top Entrance Exams for MBBS Admission',
        para: 'Securing a seat in a reputed medical college is a dream for many aspiring doctors in India. The journey to an MBBS degree begins with taking various entrance exams, which serve as the gateway to...',
        link: 'Read more',
        img: img2
    },
    {
        name: 'What are the Prospects in GNM Nursing in India?',
        para: 'Nursing is a vital part of the healthcare system, and in India, the demand for qualified nursing professionals is continually rising. General Nursing and Midwifery (GNM) is a diploma course that...',
        link: 'Read more',
        img: img3
    },
    {
        name: 'Which country is the best MBBS for higher studies?',
        para: ' In today rapidly evolving business landscape, the demand for skilled professionals equipped with a solid understanding of business principles is higher than ever before. This is where the Bachelor',
        link: 'Read more',
        img: img4
    },
    {
        name: 'How is the BBA course and at which college is it best to do?.',
        para: ' In the pursuit of higher education, particularly in the field of medicine, Indian students are increasingly looking beyond their borders. The decision to study abroad for an MBBS program is a...',
        link: 'Read more',
        img: img5
    },

    {
        name: 'Is MCA Better Than B.Tech CSE?',
        para: 'When choosing a career path in the ever-evolving field of technology, many students find themselves at a crossroads: Should they pursue a Master of Computer Applications (MCA) or a Bachelor of...',
        link: 'Read more',
        img: img1
    },

];

const Blog = () => {
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner blog_banner'>
                            <h2> Stay Informed, Stay Inspired!</h2>
                            <p>Explore expert insights, educational trends, and career advice to help you stay ahead. Your journey to success starts with knowledge!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col text-center">
                        <h1 className='exploretitle text-center'>Transforming Dreams into Reality</h1>
                        <p className='explore_para'>We believe that every student has unique strengths and potential. At Get Fast Education, we provide personalized counseling and mentorship to help students navigate their educational journey with confidence and clarity.</p>

                    </div>
                </div>
                <div className="row">
                    {Blogs.map((item, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
                            <div className="card text-center h-100 w-100 shadow border-0 p-0 cardcls">
                                <img src={item.img} className='img-fluid' alt={item.name} style={{ width: '100%', height: 'auto' }} />
                                <div className="card-body text-start p-3">
                                    <h6 className="card-title mt-1">{item.name || 'No Name'}</h6>
                                    <small className='fs-6'>{item.para}</small>
                                    <NavLink href={item.link} className="nav-link fs-5 blog_btn p-0 mt-2">Read more<i className=" mx-2 fa fa-angle-double-right"></i></NavLink>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default Blog
