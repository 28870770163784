import React, { useState } from "react";
import CollageTable from '../../Pages/Collegemianpage/CollageTable'
import Popupform from '../Popupform/Popupform';

const Innerpage = () => {

    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner'>
                            <h2>Jawaharlal Nehru University - [JNU], New Delhi</h2>
                            <p>At Get Fast Education, we are committed to guiding students toward academic success and career excellence. <br /> With expert educators and personalized support, we help you unlock a world of opportunities.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row m-auto">
                    <div className='text-center'>
                        <h4 className='mt-5'>Are You Interested in this College?</h4>

                        <div className='mt-4'>
                            {/* Buttons to Open the Popup */}
                            <button onClick={handleOpenPopup} className="mybtn rounded-pill mx-2">
                                Apply Now
                            </button>
                            <button onClick={handleOpenPopup} className="mybtn bg-primary rounded-pill">
                                Download Boucher
                            </button>
                        </div>

                        {/* Popup Form */}
                        {showPopup && (
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="popup-overlay">
                                            <div className="popup-content">
                                                <button className="close-btn" onClick={handleClosePopup}>
                                                    &times;
                                                </button>
                                                <Popupform />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div className="row">
                    <div className="">
                        <CollageTable />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Innerpage
