import React from 'react'
import Tophead from './Header/Tophead'
import Footer from './Footer/Footer'

const Weblayout = ({ children }) => {
  return (
    <div>
        <Tophead />
            <div>{children}</div>
        <Footer />
    </div>
  )
}

export default Weblayout