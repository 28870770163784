import React from 'react'
import axios from "axios";

import cont_icon from "../../Components/Images/contact.png"

import icon1 from '../Images/newicon (8).png'
import icon2 from '../Images/newicon (6).png'
import icon3 from '../Images/newicon (2).png'
import icon4 from '../Images/newicon (4).png'
import config from '../../config/config';

const Popupform = () => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        data.terms = formData.get("terms") === "on"; // Convert checkbox to boolean

        console.log("Sending data:", data);

        try {
            const response = await axios.post(
                `${config.API_BASE_URL}/api/user/submitformdata`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 201 || response.status === 200) {
                alert("Form submitted successfully!");
            } else {
                alert("Submission failed: " + response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form.");
        }
    };

    return (
        <div>
            <div className="Form_container container mb-5">
                <div className="row m-0 p-0">
                    <div className="col-lg-4 m-0 p-0" style={{ backgroundColor: '#f5faff' }}>
                        <div className='form_left_content'>
                            <div>
                                <p className='Form_left_head'>
                                    How Getfast Education helps you in Admission
                                </p>

                                <div className="container mt-4">
                                    <div className="row">
                                        <div className='col-6'>
                                            <div className="card w-100 mb-2 text-center">
                                                <div className="form_left_sm_crd">
                                                    <div>
                                                        <img src={icon1} alt="icon" className='w-50' />
                                                    </div>
                                                    <div className="form_left_sm_title">
                                                        <p>Brochure Details</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="card w-100 mb-2 text-center">
                                                <div className="form_left_sm_crd">
                                                    <div>
                                                        <img src={icon2} alt="icon" className='w-50' />
                                                    </div>
                                                    <div className="form_left_sm_title">
                                                        <p>Check Detailed Fees</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-6'>
                                            <div className="card w-100 mb-2 text-center">
                                                <div className="form_left_sm_crd">
                                                    <div>
                                                        <img src={icon3} alt="icon" className='w-50' />
                                                    </div>
                                                    <div className="form_left_sm_title">
                                                        <p>Shortlist & Apply</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="card w-100 mb-2 text-center">
                                                <div className="form_left_sm_crd">
                                                    <div>
                                                        <img src={icon4} alt="icon" className='w-50' />
                                                    </div>
                                                    <div className="form_left_sm_title">
                                                        <p>Application Deadlines</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="">
                            <div className='text_cont'>
                                <img src={cont_icon} alt="" className="icon" />
                                <div className="text-container">
                                    <div className="heading">Register Now To Apply</div>
                                    <div className="heading2">Get details and latest updates</div>
                                </div>
                            </div>

                            <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="input-field">
                                        <input required autoComplete="off" type="text" name="name" id="name" />
                                        <label htmlFor="name">Full Name</label>
                                    </div>

                                    <div className="input-field">
                                        <input required autoComplete="off" type="email" name="email" id="email" />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="input-field">
                                        <input required autoComplete="off" type="tel" name="phone" id="phone" />
                                        <label htmlFor="phone">Phone</label>
                                    </div>

                                    <div className="input-field">
                                        <input required autoComplete="off" type="text" name="city" id="city" />
                                        <label htmlFor="city">City</label>
                                    </div>
                                </div>

                                <div className="input-field">
                                    <select required name="course" id="course" defaultValue="">
                                        <option value="" disabled>Select Course</option>
                                        <option value="btech">BTech</option>
                                        <option value="mtech">MTech</option>
                                        <option value="mba">MBA</option>
                                        <option value="bca">BCA</option>
                                        <option value="msc">MSc</option>
                                        <option value="ma">MA</option>
                                        <option value="phd">PhD</option>
                                        <option value="bba">BBA</option>
                                        <option value="mca">MCA</option>
                                        <option value="bsc">BSc</option>
                                        <option value="ba">BA</option>
                                        <option value="bcom">BCom</option>
                                        <option value="mcom">MCom</option>
                                        <option value="bds">BDS</option>
                                        <option value="mbbs">MBBS</option>
                                        <option value="law">LLB</option>
                                        <option value="bfa">BFA</option>
                                        <option value="mfa">MFA</option>
                                    </select>
                                </div>

                                <div className="d-flex align-items-start">
                                    <input type="checkbox" name="terms" id="termsCheckbox" className="me-2 mt-1" />
                                    <label htmlFor="termsCheckbox" className="heading2 mb-0">
                                        By submitting this form, you accept and agree to our <a href="/terms&condition">Terms of Use</a>.
                                    </label>
                                </div>

                                <div className="formbtn">
                                    <button type="submit" className="mybtn float-right">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Popupform
