import React from 'react'

const Privacypolicy = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner cousepage_banner'>
                            <h2 className=' '>Privacy Policy – Getfast Education</h2>
                            <p className=''>Welcome to Getfast Education. Your privacy is important to us, and this Privacy Policy outlines how we collect, <br /> use, and safeguard your personal information when you access our platform.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row">
                    <div className="col">
                        <div className=''>
                            <h2 className='exploretitle fs-4 '>Introduction</h2>
                            <p className='explore_para text-start'>Welcome to Getfast Education. Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our platform. By accessing Getfast Education, you agree to the terms outlined in this policy.</p>


                        </div>
                        <div className='info-section'>
                            <h2 className='exploretitle fs-4 mb-3'>Information We Collect</h2>
                            <p className='explore_para text-start'>
                                To provide you with the best user experience, we may collect the following types of information:
                            </p>

                            <h5 className="fw-bold mt-3">Personal Information</h5>
                            <ul className="list-unstyled ">
                                <li><i className="  "></i>Name, email address, and contact number (when you register, contact us, or subscribe to our newsletters).</li>
                                <li><i className=" "></i>Any other details you voluntarily provide through contact forms or inquiries.</li>
                                <li><i className=" "></i>IP address, browser type, and device details.</li>
                                <li><i className="  "></i>Pages visited, time spent on our website, and browsing behavior.</li>
                            </ul>
                        </div>

                        <div className=''>
                            <h2 className='exploretitle fs-4 '>How We Use Your Information</h2>
                            <p className='explore_para text-start'>We collect and use your information for the following purposes:</p>

                            <ul className="list-unstyled">
                                <li> <strong>Providing Information:</strong>  Ensuring accurate and up-to-date details about universities, courses, and admission processes.</li>
                                <li> <strong>Enhancing User Experience: </strong>Personalizing content based on your preferences and browsing behavior.</li>
                                <li> <strong>Improving Website Performance:</strong> Analyzing user behavior to enhance functionality and fix potential issues.</li>
                                <li><strong>Communication & Support:</strong> Responding to inquiries, feedback, and customer service requests.</li>
                            </ul>
                        </div>


                        <div className=''>
                            <h2 className='exploretitle fs-4 '>Data Security & Protection</h2>
                            <p className='explore_para text-start'>We take appropriate measures to safeguard your personal data from unauthorized access, alteration, disclosure, or destruction. These include:</p>

                            <ul className="list-unstyled">
                                <li> Secure servers and encrypted databases to store sensitive information.</li>
                                <li>Limited access to personal data by authorized personnel only.

                                </li>
                                <li>Regular security updates and vulnerability scans to prevent cyber threats.</li>

                            </ul>
                        </div>

                        <div className=''>
                            <h2 className='exploretitle fs-4 '> Third-Party Links & External Websites</h2>
                            <p className='explore_para text-start'>Getfast Education may contain links to third-party websites, including universities, admission portals, and educational resources. These external sites have their own privacy policies, and we do not take responsibility for their content, data practices, or security.</p>
                        </div>
                        <div className=''>
                            <h2 className='exploretitle fs-4 '> Modifications & Updates to Privacy Policy</h2>
                            <p className='explore_para text-start'>Getfast Education reserves the right to modify, update, or revise this Privacy Policy at any time without prior notice. We encourage users to review this page periodically for the latest updates.</p>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacypolicy
