import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoimg from "../Images/GET-FAST-EDUCATION-LOGO-1.png";
import Allcourse from "../Course/Allcourse";
import config from "../../config/config";
import axios from "axios";

const Nav = () => {
    const [navcourse, setNavcourse] = useState([]);

    useEffect(() => {
        const fetchNavCourse = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_allNavCourse`
                );
                const fetchedData = response.data.allnavCourse || [];
                setNavcourse(fetchedData);
                console.log("Fetched Data:", fetchedData); // Debugging log
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchNavCourse();
    }, []);

    return (
        <div className="container-fluid border-top Nav_div">
            <div className="row">
                {/* All course component */}
                <div className="col-lg-1 col-md-4 col-sm-10 me-0 pe-0">
                    <p>
                        <Allcourse />
                    </p>
                </div>

                <div className="col-lg-11 col-md-8 col-sm-2 mx-0 px-0 py-0 my-0 mb-0 pb-0">
                    <nav className="navbar navbar-expand-lg p-0 m-0">
                        <button
                            className="navbar-toggler text-light"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fa fa-bars"></i>
                        </button>

                        {/* Offcanvas Menu */}
                        <div
                            className="offcanvas offcanvas-start"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header text-dark">
                                <Link to="/">
                                    <img src={logoimg} alt="logoimg" className="w-50" />
                                    <p className="mt-3 text-dark">
                                        Histudy is an education website template. You can customize all.
                                    </p>
                                </Link>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>

                            <div className="offcanvas-body justify-content-between">
                                <ul className="navbar-nav d-flex align-items-center navcls" style={{ fontSize: "15px" }}>
                                    {navcourse.length > 0 ? (
                                        navcourse.map((course, index) => (
                                            <li className="nav-item" key={index}>
                                                <a
                                                    className="nav-link nav_bar_item"
                                                    href={`/Collegespage/${course._id}`}
                                                >
                                                    {course.pageName}
                                                </a>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="nav-item">No courses available</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Nav;
