import React from 'react'

// get about  images
import Faq from '../../Components/Faq/Faq';
import Whychose from '../../Components/Whychoseus/Whychose';



// card icons here 

import img1 from '../../Components/Images/001-bulb.png'
import img2 from '../../Components/Images/002-hat.png'
import img3 from '../../Components/Images/003-id-card.png'
import img4 from '../../Components/Images/004-pass.png'


const courses = [
  {
    id: 1,
    iconimg: img1,
    title: "University Listings",
    description: "Explore a wide range of online universities and courses, all in one place. Simplify your application process with Getfast Education.",
  },
  {
    id: 2,
    iconimg: img2,
    title: "Course Insights",
    description: "We connect you with the best online universities, providing transparent insights into faculty, course quality, and fee structures.",
  },
  {
    id: 3,
    iconimg: img3,
    title: " Advanced Comparison Tools",
    description: "Compare universities based on key factors like e-learning systems, EMI options, and accreditation to make an informed decision.",
  },
  {
    id: 4,
    iconimg: img4,
    title: "Unbiased Guidance",
    description: "Enroll in top-rated online programs and take a step toward a successful career with confidence and clarity.",
  },
];



const Aboutus = () => {
  return (
    <div>

      {/* banner */}

      <div className="container-fluid">
        <div className="row">
          <div className="col p-0 m-0">
            <div className='background_banner'>
              <h2>Empowering Your Educational Journey </h2>
              <p>At Get Fast Education, we are committed to guiding students toward academic success and career excellence. <br /> With expert educators and personalized support, we help you unlock a world of opportunities.</p>
            </div>
          </div>
        </div>
      </div>

    



      {/* cards section  */}


      <div className="container text-center mt-4 pt-5">
        <h2 className="exploretitle">How We Help Students</h2>
        <p className="explore_para">
          Getfast Education simplifies online learning by providing transparent insights into universities, courses, and career opportunities. Find the best online programs tailored to your needs.
        </p>
        <div className="row">
          {courses.map((course) => (
            <div key={course.id} className="col-lg-3 col-md-6 mb-4">
              <div className="card h-100 shadow course-card text-start p-3">
                <img src={course.iconimg} alt="" className='w-25' />
                <h4 className="exploretitle fs-4">{course.title}</h4>
                <p className="explore_para text-start ">{course.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* --------------------------------------------------- */}
      {/* why choose us sectionn  */}
      {/* --------------------------------------------------- */}


      <Whychose />

      {/* ------------------------------------------------- */}
      {/* faq section  */}
      {/* ------------------------------------------------- */}

      <Faq />

    </div>
  )
}

export default Aboutus
