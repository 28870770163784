import React from 'react'

const Term = () => {
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner'>
                            <h2>Terms & Conditions – Getfast Education </h2>
                            <p>At Get Fast Education, we are committed to guiding students toward academic success and career excellence. <br /> With expert educators and personalized support, we help you unlock a world of opportunities.</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col m-0 p-0">
                            <div>
                                <h2 className='exploretitle'>Terms & Conditions</h2>
                                <p className='explore_para text-start'>Welcome to Getfast Education. By accessing and using our platform, you agree to comply with the following Terms & Conditions. Please read them carefully before proceeding.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>1. General Information</h2>
                                <p className='explore_para text-start'>Getfast Education provides unbiased, detailed, and accurate information about online universities and courses. While we strive to ensure the accuracy of the information presented, we do not guarantee the completeness or absolute correctness of all details. Universities may update their courses, fees, or admission criteria without prior notice.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>User Responsibilities</h2>
                                <p className='explore_para text-start'>Users must verify all information from the respective university’s official website before making enrollment decisions. Getfast Education is not responsible for any discrepancies between the information on our platform and university updates. Misuse of the website, including unauthorized data extraction or content reproduction, is strictly prohibited.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>Admission & Fees</h2>
                                <p className='explore_para text-start'>Admission to universities listed on Getfast Education is subject to eligibility criteria set by respective institutions. Fee structures, payment options, and scholarships vary across universities. We do not facilitate direct admissions or process payments on behalf of any institution. Users are advised to check the latest admission details, entrance requirements, and payment methods directly with the university.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>Third-Party Links</h2>
                                <p className='explore_para text-start'>Our platform may include links to third-party websites, including university portals and official admission pages. Getfast Education does not control or take responsibility for the content, policies, or services provided by external websites.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>Modifications & Updates</h2>
                                <p className='explore_para text-start'>Getfast Education reserves the right to update or modify these Terms & Conditions at any time without prior notice. Users are encouraged to review this page periodically for the latest updates. By using Getfast Education, you acknowledge that you have read and understood these terms and agree to abide by them. For any queries, feel free to contact us.</p>
                            </div>

                            <div>
                                <h2 className='exploretitle fs-4'>Limitation of Liability</h2>
                                <p className='explore_para text-start'>Getfast Education serves as an information aggregator and does not guarantee admission or placement at any university. We are not liable for any decisions made based on the information provided on our platform.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Term
