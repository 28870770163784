import React from 'react'
import './Faq.css';
import accimg from '../Images/about-07.webp'

const Faq = () => {
    return (
        <div>
            <div className='container-fluid mt-5'>
                <div className="row">
                    <div className="text-center my-3">
                        <h1 className='exploretitle fs-1'>
                            FAQs on Courses After 12th
                        </h1>

                    </div>
                </div>
                <div className='row mb-3 p-3'>
                    <div className="col-lg-7">
                        <details className='shadow-sm mt-3 '>
                            <summary>What are the popular courses I can pursue after 12th science?</summary>
                            <div className="faq__content fs-5 mb-2">
                                <p>After completing 12th grade with a science stream, you have a wide range of options, including:</p>
                                <ul className='fs-6 faq_ul'>
                                    <li className='m-1'>Engineering (B.Tech/B.E.) in various fields like Computer Science,</li>
                                    <li className='m-1'>Mechanical, Electrical, Civil, etc. </li>
                                    <li className='m-1'>Medical (MBBS, BDS, BAMS, BHMS)  </li>
                                    <li className='m-1'>Pure Sciences (B.Sc.) in Physics, Chemistry, Biology, Mathematics, </li>
                                    <li className='m-1'>  Nursing (B.Sc. Nursing)</li>
                                    <li className='m-1'>  Pharmacy (B.Pharm)</li>
                                    <li className='m-1'>  Architecture (B.Arch)</li>
                                </ul>
                            </div>
                        </details>
                        <details className='shadow-sm mt-3'>
                            <summary>What are the career options available after 12th commerce?</summary>
                            <div className="faq__content fs-5 mb-2">
                                <p>After completing 12th grade with a science stream, you have a wide range of options, including:</p>
                                <ul className='fs-6 faq_ul'>
                                    <li className='m-1'>    B.Com (Bachelor of Commerce),</li>
                                    <li className='m-1'>BBA (Bachelor of Business Administration) </li>
                                    <li className='m-1'>CA (Chartered Accountancy)</li>
                                    <li className='m-1'>CS (Company Secretary) </li>
                                    <li className='m-1'>  CMA (Cost and Management Accounting)</li>
                                    <li className='m-1'>Economics (B.A. in Economics)</li>
                                    <li className='m-1'> Finance and Accounting courses</li>
                                </ul>
                            </div>
                        </details>
              
                        <details className='shadow-sm mt-3'>
                            <summary>What are the best courses for students who completed 12th in arts/humanities?</summary>
                            <div className="faq__content fs-5 mb-2">
                                <p>Arts and humanities students have a plethora of courses to choose from, including:</p>
                                <ul className='fs-6 faq_ul'>
                                    <li className='m-1'> B.A. (Bachelor of Arts) in subjects like Psychology, Sociology, History, Political Science, English Literature</li>
                                    <li className='m-1'>Journalism and Mass Communication (B.A. or B.J.M.C) </li>
                                    <li className='m-1'> Law (Integrated LLB) </li>
                                    <li className='m-1'>Design and Fine Arts (BFA, B.Des) </li>
                                    <li className='m-1'>  Nursing (B.Sc. Nursing)</li>
                                    <li className='m-1'>  Education (B.Ed.)</li>
                                    <li className='m-1'>  Social Work (B.S.W.)</li>
                                </ul>
                            </div>
                        </details>

                        <details className='shadow-sm mt-3'>
                            <summary>Are there any vocational courses available after 12th</summary>
                            <div className="faq__content fs-5 mb-2">
                                <p>  Yes, there are several vocational courses designed to provide practical skills and training for specific careers:</p>
                                <ul className='fs-6 faq_ul'>
                                    <li className='m-1'>Hotel Management (BHM)</li>
                                    <li className='m-1'>Event Management (BBA in Event Management) </li>
                                    <li className='m-1'>Travel and Tourism Management (BBA or BTTM)</li>
                                    <li className='m-1'>Animation and Multimedia </li>
                                    <li className='m-1'> Fashion Design</li>
                                    <li className='m-1'> Culinary Arts</li>
                                </ul>
                            </div>
                        </details>


                        <details className='shadow-sm mt-3'>
                            <summary>How do I choose the right course after 12th?</summary>
                            <div className="faq__content fs-5 mb-2">
                                <p>After completing 12th grade with a science stream, you have a wide range of options, including:</p>
                                <ul className='fs-6 faq_ul'>
                                    <li className='m-1'>Engineering (B.Tech/B.E.) in various fields like Computer Science,</li>
                                    <li className='m-1'>Mechanical, Electrical, Civil, etc. </li>
                                    <li className='m-1'>Medical (MBBS, BDS, BAMS, BHMS)  </li>
                                    <li className='m-1'>Pure Sciences (B.Sc.) in Physics, Chemistry, Biology, Mathematics, </li>
                                    <li className='m-1'>  Nursing (B.Sc. Nursing)</li>
                                    <li className='m-1'>  Pharmacy (B.Pharm)</li>
                                    <li className='m-1'>  Architecture (B.Arch)</li>
                                </ul>
                            </div>
                        </details>
                    </div>

                    <div className="col-lg-5">
                        <div className=' text-center p-3'>
                            <img src={accimg} alt="" className='w-75 rounded-4' />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Faq
