import React from 'react'
import Topcolleges from '../../Topcolleges/Topcolleges'

const Topuniversities = () => {
    return (
        <div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>List of Top Colleges in India Based on 2025 Ranking</h2>
                            <p>India is home to some of the finest educational institutions, renowned for their academic excellence, infrastructure, and placement opportunities</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <Topcolleges />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Topuniversities
