import React, { useState } from 'react';
import './Allcourse.css';
import { NavLink } from 'react-router-dom';

const Allcourse = () => {
    const [toggled, setToggled] = useState(false);
    const [showText, setShowText] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(null); // Track the selected course
    const [activeIndex, setActiveIndex] = useState(null);

    const handleSidebarToggle = () => setToggled(!toggled);
    // const handleMenuClick = () => setShowText(!showText);

    const handleCourseClick = (courseName) => {
        setSelectedCourse(courseName);
        setShowText(false);
    };

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const handleBackButtonClick = () => {
        setSelectedCourse(null);
        setShowText(true); // Show course list
    };


    // sidebar links data 
    const courses = [
        { name: 'B.Tech' },
        { name: 'M.Tech' },
        { name: 'MBBS' },
        { name: 'B.Com' },
        // { name: 'M.Com' },
        { name: 'B.Sc' },
        { name: 'B.Sc Nursing' },
        { name: 'BBA' },
        { name: 'MBA' },
        { name: 'B.A' },
        { name: 'Medical' },
        { name: 'Design' },
        { name: 'LAW' },
        { name: 'ARTS' },
        { name: 'Animation' },
        { name: 'Engineering' },
        { name: 'Architecture' },
        { name: 'Science' },
        { name: 'Pharmacy' },
        { name: 'Management' }
    ];

    return (
        <div>
            {/* Sidebar  open close*/}
            {toggled && (
                <div className={`sidebar ${toggled ? 'toggled' : ''}`}>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center nav-item'>
                            <p className=' p-2  courseheading '>All Courses</p>
                            <NavLink className="btn-close nav-link text-light" onClick={handleSidebarToggle}></NavLink>
                        </div>

                        {/* Back button inside sidebar */}
                        {!showText && (
                            <div className='' >
                                <NavLink className='backbtn nav-link' onClick={handleBackButtonClick}>  <span className='fa fa-angle-left me-2 fs-5 '></span> Modify </NavLink>
                            </div>
                        )}

                        <div className="p-0 m-0">
                            {/* Search bar */}
                            <form className="nosubmit form-inline">
                                <input className="nosubmit form-control w-100 w-sm-25" type="search" placeholder="Search Course..." />
                            </form>
                        </div>
                    </div>


                    {/* Main course list */}
                    {showText && (
                        <div className='courselist'>
                            <ul className='list-group list-group-flush'>
                                {courses.map((course, index) => (
                                    <li key={index} className="list-group-item outside_ul">
                                        <NavLink to={course.link || '#'} className="nav-link text-start m-0 p-0" onClick={() => handleCourseClick(course.name)}>
                                            {course.name} <i className='fa fa-angle-right float-right fw-bold mt-1'></i>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}


                    {/* Conditional rendering based on selected course */}

                    {/* B.tech first ul li */}
                    {!showText && selectedCourse === 'B.Tech' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(0)}>
                                Top Cities & States <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 0 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/Delhi" className='nav-link m-0 p-0'>Top B.Tech colleges Delhi
                                            <span className='float-right fa fa-angle-right'></span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/mumbai" className='nav-link m-0 p-0'>Top B.Tech colleges Mumbai  <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/jaipur" className='nav-link m-0 p-0'>Top B.Tech colleges Jaipur  <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>


                            {/*  Btech second ul li  */}
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Browse By B.Tech Streams <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Computer Science <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Mechanical Engineering <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>B.Tech in Electronics & Communication <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>


                            {/* Btech third ul li section  */}
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(2)}>
                                College Predictor <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 2 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>See ALL</NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    )}
                    {/* Btech section end here*/}



                    {/* M.tech section  */}
                    {!showText && selectedCourse === 'M.Tech' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Browse By M.Tech Streams <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/CS" className='nav-link m-0 p-0'>Computer Science <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Electronic" className='nav-link m-0 p-0'>Electronic Engineering <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Civil" className='nav-link m-0 p-0'>Civil Engineering <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>


                            {/* M.Tech second  section  */}
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(2)}>
                                College Predictor<span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 2 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>See All</NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    )}





                    {/* MBBS section */}
                    {!showText && selectedCourse === 'MBBS' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Top College <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/mbbspage" className='nav-link m-0 p-0'>Top MBBS colleges in Bangalore <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/chennai" className='nav-link m-0 p-0'>Top M.Tech colleges Chennai <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}


                    {/* B.Com Section  */}

                    {!showText && selectedCourse === 'B.Com' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Browse By B.Com. Streams <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/Accounting" className='nav-link m-0 p-0'>Accounting <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Finance" className='nav-link m-0 p-0'>Finance <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>


                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(2)}>
                                College predictor <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 2 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>See All</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}



                    {/* B.Se Section start  */}
                    {!showText && selectedCourse === 'B.Sc' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(0)}>
                                Browse By B.Sc Streams <span className='float-right m-2 fs-4'>+</span>
                            </div>

                            <div className={`accordion-content ${activeIndex === 0 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/Math" className='nav-link m-0 p-0'>Mathematics <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/physics" className='nav-link m-0 p-0'>Physics <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/chemistry" className='nav-link m-0 p-0'>Chemistry <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>


                            {/* B.Se second ul li start  */}
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                College Predictor  <span className='float-right m-2 fs-4'>+</span>
                            </div>

                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>See ALL</NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    )}



                    {/* Bse.Nursing  section */}
                    {!showText && selectedCourse === 'B.Sc Nursing' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Browse By B.Sc. (Nursing) <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/bseNpage" className='nav-link m-0 p-0'>Nursing <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Medical Laboratory  <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Medical Technology   <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}



                    {/* BBA Section  */}

                    {!showText && selectedCourse === 'BBA' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Browse By BBA Streams <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Marketing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Finance</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>HR Management</NavLink>
                                    </li>
                                </ul>
                            </div>

                            {/* BBA section 2 ul li   */}
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(2)}>
                                College Predictor<span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 2 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>See All</NavLink>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    )}





                    {/* MBA   section */}
                    {!showText && selectedCourse === 'MBA' && (
                        <div className="accordion">
                            <div className="accordion-header text-start w-100" onClick={() => handleAccordionClick(1)}>
                                Top Cities & State <span className='float-right m-2 fs-4'>+</span>
                            </div>
                            <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/" className='nav-link m-0 p-0'>Top MBA colleges <span className='float-right fa fa-angle-right'></span></NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    )}

                    {/* MBA section end  */}
                </div>
            )}

            {/* Main content */}
            <main className=''>
                {!toggled && (
                    <NavLink className=" nav-link coursebtn" onClick={handleSidebarToggle}>
                        All Courses
                    </NavLink>
                )}
            </main>
        </div>
    );
}

export default Allcourse;
