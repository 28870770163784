import React from 'react'
import { NavLink } from 'react-bootstrap';
const tabcardData = [
    { id: 1, courses: ["BE/B.Tech ", "BA ", "B.Com", "B.Sc", "B.Ed", "MBA/PGDM ", "Arts ", "Ph.D",] },
    { id: 1, courses: ["BBA/BMS ", "M.Phil/Ph.D ", "MA", "M.Phil/Ph.D in Engineering", "M.Phil/Ph.D in Arts"] },
];

const Topcourses = () => {
    return (
        <div>

            <div>
                <div className="container-fluid Admission_cont">
                    <div className="container">
                        <h1 className='exploretitle mx-3 mt-3'>
                            Top Courses 2025
                        </h1>
                        <div className="row">
                            <div className="col">
                                <div className="add_tabs_container">
                                    {tabcardData.map((card) => (
                                        <div key={card.id} className="add_tab_item">
                                            <ul className="addm_tab_ul_li">
                                                {card.courses.map((course, index) => (
                                                    <li key={index} className="addm_tab_li">
                                                        <NavLink to={`/${course.toLowerCase().replace(/\s+/g, '-')}`} className="nav-link">
                                                            {course}  <i className="fa fa-angle-double-right"></i>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Topcourses
