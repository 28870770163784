import React, { useState } from 'react'
// import logoimg from '../Images/GET-FAST-EDUCATION-LOGO-1.png'
import logoimg from '../Images/getfastlightlogo.png'
import Selectgoal from './Selectgoal'
import Login from './Login';
import { Modal } from 'react-bootstrap';
import Explore from './Explore';
import { Link, NavLink } from 'react-router-dom';
import Nav from '../Navbar/Nav';



import { useLocation } from "react-router-dom";

const Tophead = () => {
    // Notification bell icon function
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();

    return (
        <div>
            {/* TOP Header section  */}
            {/* <nav className={`top_head ${location.pathname === "/" ? "transparent-nav" : "white-nav"}`}></nav> */}

            <div className={`container-fluid top_head ${location.pathname === "/" ? "transparent-nav" : "white-nav"}`}>
                <div className="row ">
                    {/* first col  */}
                    <div className="col-lg-3  p-0 m-0">
                        <div className='d-flex'>
                            {/* logo span  */}
                            <div>
                                <Link to="/"> <img src={logoimg} className='img-fluid p-2 mx-3 mt-1' alt="logoimgGetfast" style={{ maxWidth: '150px' }} /></Link>
                            </div>
                            {/* selectgoal span  */}
                            <span>
                                <div className=''>
                                    <Selectgoal />
                                </div>
                            </span>
                        </div>
                    </div>


                    {/* second col  */}
                    <div className="col-lg-5 p-0 m-0">
                        {/* Form span  */}
                        <span>
                            <form class="nosubmit form-inline pt-3 m-auto w-100">
                                <input class="nosubmit form-control bg-light w-100  w-sm-25" type="search" placeholder="Search For Colleges, Exam,Course and More..." />
                            </form>
                        </span>
                    </div>

                    {/* three col  */}
                    <div className="col-lg-4">
                        <div className='pt-3'>
                            {/* Review span1  */}
                            <span className="headspan1">
                                <span className="">
                                    <NavLink to="/review" className="Reviewbtn m-0 p-0">
                                        Write a Review
                                    </NavLink>
                                </span>
                            </span>



                            {/* Explore page import --------------------------------------
                             span2*/}
                            <span className="headspan2 ">
                                <Explore />
                            </span>


                            {/* Login REG span*/}
                            <span className="headspan3 float-right d-flex">

                                {/* Notification box */}
                                <span className='notification_span'>

                                    <button class="button" onClick={handleShow}>
                                        <svg viewBox="0 0 448 512" class="bell"><path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path></svg>
                                    </button>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Notification</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>This is your notification.</Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>
                                    </Modal>
                                </span>





                                {/* ----------------------------------------------------*/}
                                {/* Login Page import here  */}
                                <div className=''>
                                    <Login />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <Nav />
                    </div>
                </div>


            </div>


        </div>
    )
}

export default Tophead
