import React from 'react'
import Faq from '../Faq/Faq'

const guidelines = [
    {
        title: "Be Factual",
        description: "Read the instructions provided along with the questions carefully and try to include the facts to the best of your knowledge."
    },
    {
        title: "Be Decent",
        description: "Talking about your alma mater should always have decency, even when criticising it. No rash words should be used in the content. This may lead to the approval being rejected."
    },
    {
        title: "Be Original",
        description: "Write your own experience. Don't Copy. Most elaborate reviews might bring extra rewards. Write your review on your own, don't let anyone else use your identity."
    },
    {
        title: "Be Patient",
        description: "The review has 8 steps, each step covering one aspect of your college life. So, answer them with patience so they add value to the reader. You can always complete the review form in phases."
    },
    {
        title: "Be Social",
        description: "Once you are done with your review, share the review form with as many students as possible. More reviews mean more rewards."
    }
];


const rules = [
    "Monthly Winners will be announced at the start of each month.",
    "Top 3 monthly winners will each receive a bonus cash reward of ₹1,00,000, ₹50,000, ₹30,000 respectively. Candidates in positions 4th - 10th, 11th - 20th and 21st - 25th on the leaderboard at the end of the month will get a ₹10,000, ₹500 and ₹300 respectively bonus cash prize. This is separate from the amount earned through individual referrals.",
    "To be eligible for the bonus cash reward, the 1st prize winner should have at least 150 successful referrals; the 2nd prize winner should have at least 120 successful referrals; the 3rd prize winner should have at least 100 successful referrals; 4th–10th position holders must have at least 50 referrals, 11th-20th position must have at least 30 referrals and the remaining 21st-25th must have 15 referrals each.",
    "There are two ways to earn reward points: Write a college review to get up to 1000 points (₹500). Minimum of 20 points (₹10).",
    "Share your referral code with your friends to encourage them to write college reviews. Upon approval of such reviews, you will earn up to 200 points(₹100) to Minimum 10 points (₹5). When the review submitted using your referral code is approved, you will be rewarded in cash.",
    "For each successfully approved review for selected colleges obtained through a referral, the user getting the referral will get assured 200 points (₹100).",
    "A successful referral is when the review submitted using your referral code is approved. 1 point = ₹0.5",
    "If Collegedunia has collected more than 20 reviews in a month for a specific college, the referral reward and review writing reward will be downgraded to 20 points for referral and 40 points for writing a review."
];

const Reviewcomp = () => {
    return (
        <div>

            <div className="container-fluid mb-5 pb-5 ">
                <div className="row mt-0 pt-0">
                    <div className="col m-0 p-0">
                        <div className="mybackground mt-0 pt-0">
                            <form class="nosubmitcls form-inline searchbar formcls mt-0 pt-0">
                                {/* slider text here  */}
                                <div className="mt-3 reviw_contenttext">
                                    <p className='guest_text' >Hello Guest</p>
                                    <span>Write a Review, Refer and Win Up To </span>
                                    <h3 className='review_num'>₹100000*</h3>
                                    <h5 className='review_para'>Earn up to ₹200 ( 400 points) for approved reviews. Earn ₹20 (40 points) per referral. Receive ₹500 (1000 points) for writing & ₹100 (200 points) for every referred review from the selected colleges.</h5>


                                    <div class="input-group w-100">
                                        <input type="text" class=" nosubmitcls  bg-light p-4 form-control" placeholder="Search for colleges, exams, courses and more." aria-label="Username" aria-describedby="basic-addon1" />
                                        <div class="input-group-prepend">
                                            <button class="searchbtn mybtn" type="">Search</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <h3 className='fw-bold fs-2'>Do's & Dont's - Points to Consider While Writing a Review</h3>
                <ul>
                    {guidelines.map((item, index) => (
                        <li key={index} className='mt-3'>
                            <strong>{item.title} - </strong>{item.description}
                        </li>
                    ))}
                </ul>


                <h3 className='fw-bold mt-5'>Rules</h3>
                <ul>
                    {rules.map((rule, index) => (
                        <li key={index} className='mt-3'>{rule}</li>
                    ))}
                </ul>
            </div>



            <Faq />


        </div>
    )
}

export default Reviewcomp
