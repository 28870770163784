import { NavLink } from 'react-router-dom';
import React from 'react'

import img1 from '../../Images/expimg (1).png';
import img2 from '../../Images/expimg (2).png';
import img3 from '../../Images/expimg (3).png';
import img4 from '../../Images/expimg (4).png';
import img5 from '../../Images/expimg (5).png';
import img6 from '../../Images/expimg (6).png';




import crdimg1 from '../../Images/icon1.png';
import crdimg2 from '../../Images/icon2.png';
import crdimg3 from '../../Images/icon3.png';
import crdimg4 from '../../Images/icon4.png';
import crdimg5 from '../../Images/icon5.png';
import crdimg6 from '../../Images/icon6.png';
import crdimg7 from '../../Images/icon7.png';


const college = [
    { link: 'After 10th Courses', img: img1 },
    { link: 'After 10+2 Courses', img: img2 },
    { link: 'Diploma Courses', img: img3 },
    { link: 'Certification Courses', img: img4 },
    { link: 'Ph.D Research Courses', img: img5 },
    { link: 'Masters Degree/Post Graduation Courses', img: img6 },
];









const Carddata = [
    {
        id: "1",
        img: crdimg1,
        title: 'Engineering',
        heading: '8585 Colleges',
        ul1: 'BE/B.Tech',
        ul2: 'ME/M.TEch',
        ul3: 'Diploma in Engineering',
    },
    {
        id: "2",
        img: crdimg2,
        title: 'Management',
        heading: '4542 Colleges',
        ul1: 'MBA/PGDM',
        ul2: 'BBA/BMS',
        ul3: 'Executive MBA',
    },
    {
        id: "3",
        img: crdimg3,
        title: 'Commerce',
        heading: '4685 Colleges',
        ul1: 'B.Com',
        ul2: 'M.Com',
        ul3: 'Diploma',
    },
    {
        id: "4",
        img: crdimg4,
        title: 'Arts',
        heading: '6353 Colleges',
        ul1: 'BA',
        ul2: 'MA',
        ul3: 'BFA',
    },
    {
        id: "5",
        img: crdimg5,
        title: 'Medical',
        heading: '2536 Colleges',
        ul1: 'MBBS',
        ul2: 'PG Medical',
        ul3: 'UG Medical',
    },
    {
        id: "6",
        img: crdimg6,
        title: 'Design',
        heading: '1525 Colleges',
        ul1: 'B.Des',
        ul2: 'M.Des',
        ul3: 'Web Design',
    },
    {
        id: "7",
        img: crdimg7,
        title: 'LAW',
        heading: '8585 Colleges',
        ul1: 'BE/B.Tech',
        ul2: 'Diploma Engineering',
        ul3: 'ME/M.Tech',
    },
];

const Ecplorecourse = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>SEARCH FROM OVER 10000 COURSES IN INDIA</h2>
                            <p>India is home to some of the finest educational institutions, renowned for their academic excellence, infrastructure, and placement opportunities</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-5">
                <div className="row ">
                    <div className="col">
                        <div className='text-center'>
                            {/* heading */}
                            <h1 className='exploretitle fs-1'>
                                Master New Skills with Expert Guidance
                            </h1>

                            <p className='explore_para'>Gain insights from experienced educators and industry professionals who will guide you through real-world applications, hands-on projects, and in-depth learning experiences.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* card section  */}


            <div className="container">
                <div className="row pt-3 colegcontainer">
                    {college.map((item, index) => (
                        <div key={index} className="col-md-4 college_col">
                            <div className="mt-3 text-center college-card">
                                {/* Normal Image */}
                                <img src={item.img} alt="College" className="college-img w-75" />
                                {/* Text Link at Bottom */}
                                <div className="card-body">
                                    <NavLink to={item.link} className="fw-semibold text-dark">
                                        {item.link}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* second card  */}


            {/* Cards Grid Layout */}
            <div className="container-fluid mt-5 pt-5" style={{ backgroundColor: '#f6f6f6' }}>
                <div className="container">
                    <div className='text-center'>
                        {/* heading */}
                        <h1 className='exploretitle fs-1'>
                            CHOOSE BY INTEREST
                        </h1>
                        <p className='explore_para'>GetFast Education is a comprehensive platform designed for students, parents, and education professionals seeking reliable information on courses, colleges, and career opportunities</p>
                    </div>

                    <div className="row">
                        {Carddata.map(({ id, title, img, heading, ul1, ul2, ul3 }) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={id}>
                                <div className="border bg-light rounded-3 w-100 p-4">
                                    <div className="text-center">
                                        <img src={img} alt="iconimg" className='w-50 mb-2' />
                                    </div>
                                    <h4 className="text-dark fs-5 fw-semibold text-center">{title}</h4>
                                    <p className="text-secondary text-center" style={{ fontSize: '15px' }}>
                                        {heading}
                                    </p>

                                    <ul className="list-group list-group-flush text-center" style={{ fontSize: '15px' }}>
                                        <li className="list-group-item p-0">
                                            <NavLink className="carditem nav-link text-dark">{ul1}</NavLink>
                                        </li>
                                        <li className="list-group-item p-0">
                                            <NavLink className="carditem nav-link text-dark">{ul2}</NavLink>
                                        </li>
                                        <li className="list-group-item p-0">
                                            <NavLink className="carditem  nav-link  text-dark">{ul3}</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Ecplorecourse
