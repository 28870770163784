import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
// import axios from "axios";
// import config from "../../config/config";
import Popupform from '../../Components/Popupform/Popupform';

import col_logo from '../../Components/Images/col_logo1.webp'
import col_logo3 from '../../Components/Images/col_logo3.webp'
import col_logo4 from '../../Components/Images/col_logo4.webp'
import col_logo5 from '../../Components/Images/col_logo5.webp'
import col_logo6 from '../../Components/Images/col_logo6.webp'
import col_logo7 from '../../Components/Images/col_logo7.webp'
import col_logo8 from '../../Components/Images/col_logo2.webp'
import col_logo9 from '../../Components/Images/col_logo3.webp'

const tableData = [
    {
        rank: 1,
        logo: col_logo,
        college: "IIT Bombay - Indian Institute of Technology - IITB, Mumbai",
        subheading: "Mumbai, Maharashtra | AICTE, UGC Approved",
        fees: "2,30,700",
        coursename: "1st Year Fees",
        applicatoin_deadline: "16 Mar - 31 April 2025",

    },

    {
        rank: 2,
        logo: col_logo4,
        college: "IIT Delhi - Indian Institute of Technology IITD, New Delhi",
        subheading: "New Delhi, Delhi NCR ",

        fees: "2,28,650",
        coursename: " Total fees",
        applicatoin_deadline: "15 july - 23 August 2025",

    },



    {
        rank: 3,
        logo: col_logo3,
        college: "IIT Madras - Indian Institute of Technology [IITM], Chennai",
        subheading: "Chennai, Tamil Nadu | AICTE Approved",
        fees: "2,42,000",
        applicatoin_deadline: "14 june - 23 July 2025",

    },
    {
        rank: 4,
        logo: col_logo5,
        college: "IIT Kanpur - Indian Institute of Technology [IITK], Kanpur",
        subheading: "Kanpur, Uttar Pradesh | UGC Approved",
        fees: "2,29,970",
        applicatoin_deadline: "20 April - 15 june 2025",

    },
    {
        rank: 5,
        logo: col_logo6,
        college: "IIT Bombay - Indian Institute of Technology [IITB], Mumbai",
        subheading: "Mumbai, Maharashtra | AICTE, UGC Approved",
        fees: "2,28,650",
        coursename: " 1st Year Fees",
        applicatoin_deadline: "11 Mar - 10 April 2025",

    },
    {
        rank: 6,
        logo: col_logo7,
        college: "IIT Delhi - Indian Institute of Technology [IITD], Delhi",
        subheading: "New Delhi, Delhi | AICTE, UGC Approved",
        fees: "2,30,000",
        coursename: "Total fees",
        applicatoin_deadline: "12 Mar - 3 April 2025",

    },
    {
        rank: 7,
        logo: col_logo8,
        college: "IIT Kharagpur - Indian Institute of Technology [IITKGP], Kharagpur",
        subheading: "Kharagpur, West Bengal | AICTE, UGC Approved",
        fees: "2,25,000",
        coursename: " 1st Year Fees",
        applicatoin_deadline: "18 Mar - 26 Mar 2025",

    },
    {
        rank: 8,
        logo: col_logo9,
        college: "IIT Roorkee - Indian Institute of Technology [IITR], Roorkee",
        subheading: "Roorkee, Uttarakhand | AICTE, UGC Approved",
        fees: "2,15,000",
        coursename: " Total fees",
        applicatoin_deadline: "10 Arpil - 25 may 2025",

    },
    {
        rank: 9,
        logo: col_logo6,
        college: "IIT Guwahati - Indian Institute of Technology [IITG], Guwahati",
        subheading: "Guwahati, Assam | AICTE, UGC Approved",
        fees: "2,10,000",
        applicatoin_deadline: "14 Mar - 31 Mar  2025",

    }
];

const tabcardData = [
    { id: 1, courses: ["B.Tech", "M.Tech", "MBA", "MCA", "BCA",] },
    { id: 2, courses: ["M.Com", "B.Com", "BA"] },
    { id: 3, courses: ["M.Sc", "BBA/BMS", "B.Sc (Nursing)"] },

];



const Topcolleges = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent navigation
        setShowPopup(true);
        document.body.style.overflow = "hidden"; // Disable background scrolling
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        document.body.style.overflow = "auto"; // Enable background scrolling
    };



    // const [tableData, setTableData] = useState([]);

    // useEffect(() => {
    //     const fetchCollegeData = async () => {
    //         try {
    //             const response = await axios.get(
    //                 `${config.API_BASE_URL}/api/admin/get_collegespage`
    //             );
    //             const fetchedData = response.data || [];
    //             setTableData(fetchedData);
    //             console.log("fetchedData", fetchedData); // Log for debugging
    //         } catch (error) {
    //             console.error("Error fetching college data:", error);
    //         }
    //     };

    //     fetchCollegeData();
    // }, []);


    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <div>
                        <h1 className='exploretitle fs-2 pb-3'>Top Colleges</h1>
                    </div>

                    <div className="tabs-container">
                        {tabcardData.map((card) => (
                            <div key={card.id} className="tab-item">
                                <ul className="exp_tab_ul_li">
                                    {card.courses.map((course, index) => (
                                        <span key={index} className="exp_tab_li">
                                            {course}
                                        </span>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    <div className="col">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr className="">
                                        <th scope="col">Rank</th>
                                        <th scope="col">Colleges</th>
                                        <th scope="col">Application Deadline</th>
                                        <th scope="col">Course Fees</th>
                                    </tr>
                                </thead>
                                <tbody className="tablebody">
                                    {tableData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center fw-semibold">{item.rank}</td>

                                            {/* College Data TD */}
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="me-2">
                                                        <img src={item.logo} alt="" className="img-fluid" style={{ maxWidth: "40px", height: "auto" }} />
                                                    </div>
                                                    <div>
                                                        <NavLink to={`/college/${item._id}`} className="text-decoration-none text-dark fs-5 fw-bold">
                                                            {item.collegeName}
                                                        </NavLink>
                                                        <div>
                                                            <small className="fs-6">{item.subheading}</small>
                                                        </div>
                                                        <NavLink to="" className="mt-2 d-block text-decoration-none" onClick={handleOpenPopup}>
                                                            Apply Now <i className="fa fa-angle-double-right"></i>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* Placement TD */}
                                            <td>
                                                <div>
                                                    {item.applicatoin_deadline}
                                                </div>
                                            </td>

                                            {/* Fees TD */}
                                            <td>
                                                <div>
                                                    <h5> &#8377; {item.fees}</h5>
                                                </div>
                                                <small>{item.coursename}</small>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>


                        {/* Popup Modal */}
                        {showPopup && (
                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <button className="close-btn" onClick={handleClosePopup}>
                                        &times;
                                    </button>
                                    <Popupform />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Topcolleges
