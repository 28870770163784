import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../config/config';

const CollegePage = () => {
    const [college, setCollege] = useState([]);

    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_collegespage`
                );
                const fetchedData = response.data.collegePages || [];
                setCollege(fetchedData);
                console.log("fetchedData", fetchedData); // Log for debugging
            } catch (error) {
                console.error("Error fetching college data:", error);
            }
        };

        fetchCollegeData();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this college?")) {
            try {
                const response = await axios.delete(
                    `${config.API_BASE_URL}/api/admin/delete_collegespage/${id}`
                );
                if (response.status === 200) {
                    alert(response.data.message);
                    // Update the state to remove the deleted college
                    setCollege((prevData) => prevData.filter((college) => college._id !== id));
                }
            } catch (error) {
                console.error("Error deleting college:", error);
                alert("Failed to delete the college. Please try again.");
            }
        }
    };

    return (
        <div>
            <div className="container mt-5 pt-5">
                <div className="row m-2">
                    <div className="col-6">
                        <h1>College Page</h1>
                    </div>
                    <div className="col-6">
                        <Link to="/admin/add_collegepage" className="btn btn-success float-end">
                            Add College Page
                        </Link>
                    </div>
                </div>
                <div className="row p-3">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>College Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {college.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.collegeName}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => handleDelete(item._id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CollegePage;
