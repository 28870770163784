import React, { useState } from "react";
import img1 from '../Images/collg1 (1).webp';
import img2 from '../Images/collg1 (2).webp';
import img3 from '../Images/collg1 (3).webp';
import img4 from '../Images/collg1 (4).webp';
import img5 from '../Images/collg1 (5).webp';
import img6 from '../Images/collg1 (6).webp';
import img7 from '../Images/collg1 (7).webp';
import img8 from '../Images/collg1 (8).webp';
import img9 from '../Images/collg1 (9).webp';
import img10 from '../Images/collg1 (10).webp';
import img11 from '../Images/collg1 (11).webp';
import img12 from '../Images/collg1 (1).webp';
import img13 from '../Images/collg1 (2).webp';
import img14 from '../Images/collg1 (3).webp';
import img15 from '../Images/collg1 (4).webp';
import img16 from '../Images/collg1 (5).webp';
import img17 from '../Images/collg1 (6).webp';
import img18 from '../Images/collg1 (7).webp';
import img19 from '../Images/collg1 (8).webp';
import { Link } from "react-router-dom";





// Define programs array with image paths
const programs = [
    { name: 'Medical', link: '6 Courses', img: img1 },
    { name: 'Pure Sciences', link: '9 Courses', img: img2 },
    { name: 'Commerce Stream', link: '5 Courses', img: img3 },
    { name: 'Commerce Stream', link: '5 Courses', img: img3 },
    { name: 'Technology and IT', link: '10 Courses', img: img4 },
    { name: 'Technology and IT', link: '10 Courses', img: img4 },
    { name: 'Arts/Humanities Stream.', link: '8 Courses', img: img5 },
    { name: 'Vocational and Professional', link: '4 Courses', img: img6 },
    { name: 'Finance & Accounting', link: '10 Courses', img: img7 },
    { name: 'Engineering (B.Tech/B.E.)', link: '12 Courses ', img: img8 },
    { name: 'Engineering (B.Tech/B.E.)', link: '20 Courses ', img: img9 },
    { name: 'Engineering (B.Tech/B.E.)', link: '45 Courses ', img: img10 },
    { name: 'Engineering (B.Tech/B.E.)', link: '11 Courses ', img: img11 },
    { name: 'Engineering (B.Tech/B.E.)', link: '26 Courses ', img: img12 },
    { name: 'Engineering (B.Tech/B.E.)', link: '21 Courses ', img: img13 },
    { name: 'Engineering (B.Tech/B.E.)', link: '23 Courses ', img: img14 },
    { name: 'Engineering (B.Tech/B.E.)', link: '9 Courses ', img: img15 },
    { name: 'Engineering (B.Tech/B.E.)', link: '19 Courses ', img: img16 },
    { name: 'Engineering (B.Tech/B.E.)', link: '21 Courses ', img: img17 },
    { name: 'Engineering (B.Tech/B.E.)', link: '31 Courses ', img: img18 },
    { name: 'Engineering (B.Tech/B.E.)', link: '12 Courses ', img: img19 },
];

const Program = () => {

    const [visibleCount, setVisibleCount] = useState(12); // Show initial 4 cards

    const loadMoreCourses = () => {
        setVisibleCount((prevCount) => prevCount + 4); // Show 4 more on each click
    };

    return (
        <div>
            <div className="container">
                {/* heading container start  */}
                <div className="container mt-5 pt-5">
                    <div className="row">

                        <div className="col">
                            <div data-aos="fade-right"
                                data-aos-duration="2000">
                                <div className=''>
                                    {/* heading */}
                                    <h1 className='exploretitle'>
                                        Find Your Perfect University Match Online
                                    </h1>
                                    <p className="explore_para text-start">Explore over 100 online universities and compare them on essential factors such as course offerings, faculty expertise, affordability, flexibility, and student support. Whether you're looking for a degree in technology, business, arts, or science, we help you make an informed decision. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* heading container end  */}

                {/* programe cards container end  */}
                <div className="container-fluid">
                    <div className="row mt-3 pt-3 progcontainer">
                        {programs.slice(0, visibleCount).map((item, index) => (
                            <div key={index} className="col-md-2">
                                <div className="text-center h-75 shadow m-1 border-0 cardcls">
                                    <img
                                        src={item.img}
                                        className="text-center m-auto img-fluid"
                                        alt={item.name}
                                        style={{ width: "50%" }}
                                    />
                                    <div className="card-body text-center">
                                        <h5 className="card_title">
                                            {item.name || "No Name"}
                                        </h5>
                                        <Link to='/Programe-course' className="courses_btn nav-link">
                                            {item.link} <i className="fa fa-angle-double-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {visibleCount < programs.length && (
                        <div className="text-center">
                            <button onClick={loadMoreCourses} className="mybtn">
                                More Courses
                            </button>
                        </div>
                    )}
                </div>
                {/* Program / cards container end  */}

            </div>
        </div>
    );
}

export default Program;
