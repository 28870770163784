import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config/config";

const AddStreamForm = () => {
  const [colleges, setColleges] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [formData, setFormData] = useState({
    collegeId: "",
    courseId: "",  // Added courseId here to send it properly
    courseName: "",
    streamsName: "",
    streamsFee: "",
    applicationDate: "",
  });

  // Fetch colleges on component mount
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}/api/admin/get_collegespage`)
      .then((res) => setColleges(res.data))
      .catch((err) => console.error("Error fetching colleges:", err));
  }, []);

  // Fetch courses when college changes
  const handleCollegeChange = async (e) => {
    const collegeId = e.target.value;
    setSelectedCollege(collegeId);
    setFormData({ ...formData, collegeId, courseId: "" }); // Reset courseId when college changes

    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/admin/college_page_course_details/${collegeId}`);
      setCourses(Array.isArray(response.data.coursesDeatils) ? response.data.coursesDeatils : []);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setCourses([]); // Fallback to an empty array in case of an error
    }
  };

  // When course is selected, update courseId
  const handleCourseChange = (e) => {
    const courseId = e.target.value; // Get the selected course ID
    const selectedCourse = courses.find(course => course._id === courseId); // Find the selected course object
    setFormData({ ...formData, courseId, courseName: selectedCourse?.courses || "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the form data including courseId
      await axios.post(`${config.API_BASE_URL}/api/admin/streams`, formData);
      alert("Stream added successfully!");
      setFormData({
        collegeId: "",
        courseId: "",
        courseName: "",
        streamsName: "",
        streamsFee: "",
        applicationDate: "",
      });
      setCourses([]);
      setSelectedCollege("");
    } catch (error) {
      console.error("Error adding stream:", error);
    }
  };

  return (
    <div className="container mt-5 pt-5">
      <h2 className="mb-4">Add Stream</h2>
      <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
        <div className="mb-3">
          <label className="form-label">Select College:</label>
          <select
            className="form-control"
            name="collegeId"
            value={selectedCollege}
            onChange={handleCollegeChange}
            required
          >
            <option value="">Select a college</option>
            {colleges.map((college) => (
              <option key={college._id} value={college._id}>
                {college.collegeName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Select Course:</label>
          <select
            className="form-control"
            name="courseId"
            value={formData.courseId}
            onChange={handleCourseChange}  // Use the updated function to select course
            required
            disabled={!selectedCollege}
          >
            <option value="">Select a course</option>
            {Array.isArray(courses) &&
              courses.map((course) => (
                <option key={course._id} value={course._id}>  {/* Now using course._id */}
                  {course.courses} - {course.fees}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Stream Name:</label>
          <input
            type="text"
            className="form-control"
            name="streamsName"
            value={formData.streamsName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Stream Fee:</label>
          <input
            type="text"
            className="form-control"
            name="streamsFee"
            value={formData.streamsFee}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Application Date:</label>
          <input
            type="date"
            className="form-control"
            name="applicationDate"
            value={formData.applicationDate}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Add Stream
        </button>
      </form>
    </div>
  );
};

export default AddStreamForm;
