import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config';

// const tabcardData = [
//     { id: 1, courses: ["BTech Admission 2025 ", "MBA Admission 2025 ", "M.Tech Admission 2025 ", "MBBS Admission 2025 ", "B.Com Admission 2025 "] },
//     { id: 2, courses: ["BA Admission 2025 ", "BCA Admission 2025 ", "B.Sc (Nursing) Admission 2025 "] },
//     { id: 3, courses: ["D El Ed Admission 2025 ", "BBA Admission 2025 "] },
// ];



const Admission = () => {

    const [navcourse, setNavcourse] = useState([]);

    useEffect(() => {
        const fetchNavCourse = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_allNavCourse`
                );
                const fetchedData = response.data || [];
                setNavcourse(fetchedData);
                console.log("Fetched Data:", fetchedData); // Debugging log
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchNavCourse();
    }, []);
    return (
        <div>
            <div className="container-fluid Admission_cont">
                <div className="container">
                    <h1 className='exploretitle mx-3 mt-3'>
                        Top Courses 2025
                    </h1>
                    <div className="row">
                        {navcourse.length > 0 ? (
                            navcourse.map((course, index) => (
                                <div className="col-lg-4" key={index}>
                                    <NavLink
                                        to={`/Collegespage/${course._id}`}
                                        className="MyCard nav-link">
                                        <h4>{course.pageName}</h4>
                                        <p>Explore this course</p>
                                        <div className="go-corner">
                                            <div className="go-arrow">→</div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))
                        ) : (
                            <p>No courses available</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admission;
