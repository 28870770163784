import React from 'react'
import exploreimg from '../Images/exploreimg.png'
import { NavLink } from 'react-router-dom'


const Explore = () => {
    return (

        // explore container show after hover  
        <div class="explore-container pt-2">
            <span className='fa fa-th me-2 fs-5 text-white'></span>
            <span class="explorediv">Explore</span>
            {/* content here  */}
            <div class="hover-box">
                <div className="row p-4">
                    <div className="col-lg-4">
                        <ul className="list-group list-group-flush explore_item">
                            <li className="list-group-item exp_ul">
                                <NavLink to="/TopUniversitiesandColleges" className='nav-link m-0 p-0'>Top Universities & Colleges</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink to="/TopCourse" className='nav-link m-0 p-0'>Top Courses</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink to="/Admissionalert" className='nav-link m-0 p-0'> Admission Alert 2025</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink to='/Institutes' className='nav-link m-0 p-0'>Institute (counselling , loading)</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink to='/college-predictor' className='nav-link m-0 p-0'>College Predictor</NavLink>
                            </li>

                            <li className="list-group-item exp_ul">
                                <NavLink to='/Scholarship' className='nav-link m-0 p-0'>Scholarship</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <ul className="list-group list-group-flush explore_item">
                            <li className="list-group-item exp_ul">
                                <NavLink to="/StudyAbroad" className='nav-link m-0 p-0'>Study Abroad</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink to="/Exam" className='nav-link m-0 p-0'>Exam</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink className='nav-link m-0 p-0'>News</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink className='nav-link m-0 p-0'>Education Loan</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink className='nav-link m-0 p-0'>Ask a question</NavLink>
                            </li>
                            <li className="list-group-item exp_ul">
                                <NavLink className='nav-link m-0 p-0'>Test Series</NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="text-center">
                            <img src={exploreimg} alt="add img" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Explore
