import React, { useState } from 'react';
import Popupform from '../../Popupform/Popupform';
const scholarships = [
    {
        title: "Ontario Graduate Scholarship",
        internationalEligible: "Yes",
        amount: "₹870,000 ($10,000)",
        type: "College-Specific",
        level: "Doctorate",
        number: 22
    },
    {
        title: "Commonwealth Scholarship",
        internationalEligible: "Yes",
        amount: "₹1,200,000 ($14,000)",
        type: "Government Funded",
        level: "Postgraduate",
        number: 30
    },
    {
        title: "Rhodes Scholarship",
        internationalEligible: "Yes",
        amount: "₹2,000,000 ($24,000)",
        type: "Merit-Based",
        level: "Master's",
        number: 10
    },
    {
        title: "Chevening Scholarship",
        internationalEligible: "Yes",
        amount: "₹1,500,000 ($18,000)",
        type: "Fully Funded",
        level: "Master's",
        number: 50
    },
    {
        title: "Stanford Reliance Dhirubhai Fellowship Program",
        internationalEligible: "Yes",
        amount: "₹13,050,000 ($150,000)",
        type: "College-Specific",
        level: "Bachelor",
        number: 3
    },
    {
        title: "Inlaks Shivdasani Foundation Scholarships",
        internationalEligible: "Yes",
        amount: "₹8,700,000 ($100,000)",
        type: "Merit-Based",
        level: "Doctorate",
        number: 480
    },
    {
        title: "Rotary Foundation Scholarships - Global Grants",
        internationalEligible: "Yes",
        amount: "₹348,000,000 ($4,000,000)",
        type: "Company-Sponsored",
        level: "Master",
        number: "N/A"
    },
    {
        title: "Heinrich Boll Scholarships",
        internationalEligible: "Yes",
        amount: "Variable Amount",
        type: "Company-Sponsored",
        level: "Doctorate",
        number: 1500
    },
    {
        title: "Deutschland Stipendium Program",
        internationalEligible: "Yes",
        amount: "₹26,100 ($300)",
        type: "College-Specific",
        level: "Bachelor",
        number: 159
    },
    {
        title: "Australian Government Research Training Program",
        internationalEligible: "Yes",
        amount: "₹3,496,530 ($40,190)",
        type: "Merit-Based",
        level: "Doctorate",
        number: 350
    },
    {
        title: "DAAD Scholarships",
        internationalEligible: "Yes",
        amount: "₹81,258 ($934)",
        type: "Merit-Based",
        level: "Doctorate",
        number: 100000
    },
    {
        title: "Charles Wallace India Trust Scholarships",
        internationalEligible: "Yes",
        amount: "Variable Amount",
        type: "Awards",
        level: "N/A",
        number: "N/A"
    },
    {
        title: "GREAT Scholarship",
        internationalEligible: "Yes",
        amount: "₹870,000 ($10,000)",
        type: "College-Specific",
        level: "Master",
        number: 210
    }
];


const Scholarship = () => {

    const [showPopup, setShowPopup] = useState(false);
    const [selectedScholarship, setSelectedScholarship] = useState(null);

    const handleOpenPopup = (scholarship) => {
        setSelectedScholarship(scholarship);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedScholarship(null);
    };
    return (
        <div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>Scholarships to Study Abroad</h2>
                            <p>India is home to some of the finest educational institutions, renowned for their academic excellence, infrastructure, and placement opportunities</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Scholarship  card data  */}

            <div className="container py-4" style={{ backgroundColor: "#f4f4f4" }}>
                <div className="row">
                    {scholarships.map((scholarship, index) => (
                        <div className="col-lg-4 mb-3" key={index}>
                            <div className="card p-3 h-100 shadow">
                                <h5>{scholarship.title}</h5>
                                <ul>
                                    <li>International Student Eligible: <strong>{scholarship.internationalEligible}</strong></li>
                                    <li>Amount: <strong>{scholarship.amount}</strong></li>
                                    <li>Type: <strong>{scholarship.type}</strong></li>
                                    <li>Level of Study: <strong>{scholarship.level}</strong></li>
                                    <li>Number of Scholarships: <strong>{scholarship.number}</strong></li>
                                </ul>
                                <button className="mybtn" onClick={() => handleOpenPopup(scholarship)}>
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Popup Modal */}
                {showPopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <button className="close-btn" onClick={handleClosePopup}>
                                &times;
                            </button>
                            <Popupform scholarship={selectedScholarship} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Scholarship
