import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';



// all icons here for dropdownscroll box

import icon1 from '../Images/icon1.png'
import icon2 from '../Images/icon2.png'
import icon3 from '../Images/icon3.png'
//  import icon5 from '../Images/icon5.png'
import icon4 from '../Images/icon4.png'
import icon6 from '../Images/icon6.png'
import icon7 from '../Images/icon7.png'


// cities icons img import here

import img1 from '../Images/New Delhi.svg'
import img2 from '../Images/Gurgaon.svg'
import img3 from '../Images/Mumbai.svg'
import img4 from '../Images/Chennai.svg'
import img5 from '../Images/Bangalore.svg'
import img6 from '../Images/Hyderabad.svg'
import img7 from '../Images/Kolkata.svg'
import img8 from '../Images/Noida.svg'
import img9 from '../Images/Pune.svg'
import img10 from '../Images/Chennai.svg'
import img11 from '../Images/Mumbai.svg'
import img12 from '../Images/New Delhi.svg'

// card data 

const products = [
    { id: 1, title: 'New Delhi', image: img1 },
    { id: 2, title: 'Gurgaon', image: img2 },
    { id: 3, title: 'Mumbai', image: img3 },
    { id: 4, title: 'Chennai', image: img4 },
    { id: 5, title: 'Bangalore', image: img5 },
    { id: 6, title: 'Hyderabad', image: img6 },
    { id: 7, title: 'Kolkata', image: img7 },
    { id: 8, title: 'Noida', image: img8 },
    { id: 9, title: 'Pune', image: img9 },
    { id: 10, title: 'Chennai', image: img10 },
    { id: 11, title: 'Mumbai', image: img11 },
    { id: 12, title: 'Delhi', image: img12 },
];





const Selectgoal = () => {

    // select goal btn 
    const [isVisible, setIsVisible] = useState(false);
    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    // for skip 
    const handleSkipClick = () => {
        setIsVisible(false);
    };

    // show content box 
    const [showText, setShowText] = useState(true);
    const handleClick = () => {
        setShowText(!showText);
    };

    return (
        <div>
            <div>
                <div className='mt-0 pt-2 px-3 selectgole_div'>
                    <span className='selectgole_span'>Select Goal & City</span>
                    <button onClick={handleButtonClick} className="goal">Select Goal <i className="fa fa-angle-down"></i> </button>
                </div>

                {/* this  content show after on click select goal  */}
                {isVisible && (
                    <div className="contentbox shadow-lg border-0 rounded-4">
                        <div className='p-2'>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='mx-4 mt-3 text-dark fw-semibold'>
                                        <p className='sgh'>Select Your Study Preference</p>
                                    </div>
                                    {/* skip btn for outside this container  */}
                                    <NavLink to="/" className='text-info p-3 nav-link' onClick={handleSkipClick}> Skip</NavLink>
                                </div>

                                {/* single form  */}
                                <div className="p-0 m-0 searchbar">
                                    <form className="nosubmit form-inline searchbar">
                                        <input className="nosubmit form-control w-100 w-sm-25 mx-3" type="search" placeholder="Search Course..." />
                                    </form>
                                </div>
                            </div>

                            <div>
                                {showText ? (
                                    <div className='dropdownscroll p-2 m-1'>
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon1} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Engineering</p>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>BE/BTech<i className='fa fa-angle-right float-right mt-1'></i></NavLink>

                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>Diploma<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>ME/M.Tech<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                            </div>

                                        </div>
                                        {/* second row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon2} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Management</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>MBA/PGDM<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>BBA/BMS<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>MBA<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        {/* third row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon3} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Commerce</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>B.COM<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>M.COM<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>

                                            </div>
                                        </div>

                                        {/* fourth Row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon4} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Arts</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>BA<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>Ma<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>BFA<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Fifth Row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon2} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Medical</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>MBBS<i className='fa fa-angle-right float-right mt-1'></i></NavLink>

                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>PG Medical<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>

                                            </div>
                                        </div>
                                        {/* Sixth Row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon7} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Design</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>B.Des<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>M.Des<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>

                                            </div>
                                        </div>

                                        {/* seventh row  */}

                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon6} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Science</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>B.Sc<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>M.Sc<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>

                                            </div>
                                        </div>


                                        {/* eight row  */}
                                        <div className='p-2'>
                                            <div className="d-flex">
                                                <img src={icon2} alt="fonticon" className='dropdownscroll_icons' />
                                                <p className="mx-2 fw-semibold mt-2">Pharmacy</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>B.Pharm<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>
                                                <div className="col-lg-4">
                                                    <NavLink className="btn goalbtn text-start w-100" onClick={handleClick}>M.Pharm<i className='fa fa-angle-right float-right mt-1'></i></NavLink>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                ) : (

                                    <>

                                        {/* citis data container  */}
                                        <div className="container dropdownscroll">
                                            <div className='mt-3'>
                                                <strong className="my-4 p-3 mt-3">Popular Cities</strong>
                                            </div>

                                            <div className="row text-center p-2">
                                                {products.map(product => (
                                                    <div className="col-md-3" key={product.id}>
                                                        <div className="card mb-3 w-100 m-0 p-0">
                                                            <img src={product.image} alt="icons" className='w-50 m-auto citi_icon' />
                                                            <p className='mt-2'>{product.title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>




                                        {/* <div className='dropdownscroll'>

                                            <div className='mt-3'>
                                                <strong className='p-3'>Popular Cities</strong>
                                            </div>
                                            <div className="row text-center p-4">
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img1} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>New Delhi</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img2} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Gurgoan</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img3} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Mumbai</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img4} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Chennai</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row text-center p-4">
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img5} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Bangalore</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img6} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Hyderabad</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img7} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Kolkata</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img8} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Noida</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row text-center p-4">
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img9} alt="icon" className='w-50 mb-2 citi_icon' /></div>
                                                        <h6>Pune</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img1} alt="icon" className='w-50 mb-2 ' /></div>
                                                        <h6>Gurgoan</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0'>
                                                        <div><img src={img2} alt="icon" className='w-50 mb-2' /></div>
                                                        <h6>Gurgoan</h6>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='card mb-2 w-100 m-0 p-0 '>
                                                        <div><img src={img3} alt="icon" className='w-50 mb-2' /></div>
                                                        <h6>Mumbai</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>


        </div>
    )
}

export default Selectgoal
