import React from 'react'
import { NavLink } from 'react-router-dom';


import whychooseusimg from '../Images/getfastimg.png'


// get about  images
import leftimg from '../Images/whychoseimg.png';

// icons img 

import icon1 from '../Images/icons (1).png'
import icon2 from '../Images/icons (2).png'
import icon3 from '../Images/icons (3).png'
import icon4 from '../Images/icons (4).png'


// post container images 
import postimg1 from './Images/post (3).png'
import postimg2 from './Images/post (2).png'
import postimg3 from './Images/post(4).jpg'





const Whychose = () => {
    return (
        <div>


            {/* ************* Know About Get Fast Education container **************************************************************/}
            {/* ************* Know About Get Fast Education container **************************************************************/}



            <div className="container-fluid mt-5 pt-5">
                <div className="row">
                    {/* left img col  */}
                    <div className="col-lg-6">
                        <div className="text-center">
                            <img src={leftimg} alt="" className="aboutleftimg " />
                        </div>
                    </div>
                    {/* Right text col  */}
                    <div className="col-lg-6">
                        <div>
                            <div className='exploretitle mt-0'>
                                <h1 className=''>Know About Get Fast Education</h1>
                            </div>
                            <p className=' explore_para text-start mt-4'>At Get Fast Education, we are committed to helping students achieve their academic and career aspirations. With a team of experienced educators and advisors, we provide personalized guidance and comprehensive support throughout the educational journey. Our global network connects students with prestigious institutions and programs, ensuring they have access to the best opportunities available.</p>

                            {/* <p className="explore_para text-start">Our global network connects students with prestigious institutions and programs, ensuring they have access to the best opportunities available. We believe in nurturing every student’s potential by understanding their unique strengths, interests, and goals. </p> */}
                        </div>

                        <div className='mt-5'>
                            <div class="notification">
                                <div class="notiglow"></div>
                                <div class="notiborderglow"></div>
                                <div class="notititle">Personalized Guidance</div>
                                <div class="notibody">Get tailored advice to choose the right courses and career paths.</div>
                                <div class="right-arrow"><i class="fa fa-chevron-right"></i> </div>
                            </div>

                            <div class="notification">
                                <div class="notiglow"></div>
                                <div class="notiborderglow"></div>
                                <div class="notititle">Global Connections</div>
                                <div class="notibody">Access top institutions and programs worldwide to unlock new possibilities</div>
                                <div class="right-arrow"><i class="fa fa-chevron-right"></i> </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <NavLink to="/About" className="mybtn rounded-2 p-2 mx-2 mt-3">
                                Read More <i className="fa fa-angle-double-right"></i>
                            </NavLink>

                        </div>



                    </div>
                </div>
            </div>





























            {/* our post container start  */}

            <div className="container-fluid postcontainer">
                <div className="row mt-5">
                    <div className="col mt-5">
                        <h1 className='exploretitle text-center'>From Our Post.</h1>
                        <p className='text-center explore_para mt-3'>There are many variations of passages of the Ipsum available, but the majority <br /> have suffered alteration in some form, by injected humour.</p>
                    </div>
                </div>


                <div className="container-fluid m-0 p-0">
                    <div className="row p-4">
                        <div className="col-lg-4">
                            <div className="card  w-100">
                                <img src={postimg1} alt="" className='' />
                                <div className='p-4 posttextdiv'>
                                    <h4 className='fw-bold postheading'>Maximizing Early Education: The Key to a Strong Foundation</h4>
                                    <p className='text-secondary'>Receive expert guidance, test preparation, and strategic support to secure admission to top institutions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card w-100">
                                <img src={postimg2} alt="" className='' />
                                <div className='p-4 posttextdiv'>
                                    <h4 className='fw-bold postheading'>Your Success, Our Mission: Inspiring Student Journeys</h4>
                                    <p className='text-secondary'>Hear real success stories of students who achieved their academic dreams with our dedicated mentorship.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card w-100">
                                <img src={postimg3} alt="" className='' />
                                <div className='p-4 posttextdiv'>
                                    <h4 className='fw-bold postheading'>Tailored Mentorship for Exam & Admission Success</h4>
                                    <p className='text-secondary'>Receive expert guidance, test preparation, and strategic support to secure admission to top institutions.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* our post container end  */}


















            {/* Why Choose Us  start here  */}

            {/* Why choose us container   */}
            <div className="container-fluid mt-5 whychooose">
                <br />
                <br />
                <div className="row">
                    {/* img col  */}
                    <div className="col-lg-6">
                        <div className='text-center'>
                            <img src={whychooseusimg} alt="" className='whychoose_img text-center' />
                        </div>
                    </div>

                    {/* text col  */}
                    <div className="col-lg-6">
                        <div>
                            <h1 className='exploretitle'> Why Choose Us?</h1>
                            <p className='explore_para text-start mt-4'>At Get Fast Education, we are dedicated to empowering students to achieve their academic and career aspirations. Our team of experienced educators and advisors offers personalized guidance tailored to each student’s unique strengths, interests, and goals. We believe that every student has the potential to succeed, and we are committed to providing the right resources, strategies, and support to help them thrive. </p>

                            <p className='explore_para text-start'>  From course selection and skill development to career planning and mentorship, we ensure a seamless and rewarding learning experience. Our expert team crafts customized learning pathways that align with your individual ambitions, helping you stay motivated and on track. Whether you're preparing for higher education, enhancing professional skills.</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="why_icon">
                                    <img src={icon1} alt="" className='why_choose_icons' />
                                    <span className='explore_para fs-6'>Experienced Educators & Advisors</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why_icon">
                                    <img src={icon2} alt="" className='why_choose_icons' />
                                    <span className='explore_para fs-6'>Comprehensive Educational</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="why_icon">
                                    <img src={icon3} alt="" className='why_choose_icons' />
                                    <span className='explore_para fs-6'>Tailored Learning Experience</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why_icon">
                                    <img src={icon4} alt="" className='why_choose_icons' />
                                    <span className='explore_para fs-6'>Commitment to Student Success</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {/* Why choose us container end    */}
        </div>
    )
}

export default Whychose
