import React, { useEffect, useState } from 'react'
// import { useParams } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import Popupform from '../../Components/Popupform/Popupform'
import config from '../../config/config';
import axios from 'axios';



// const tableData = [
//     {
//         coursename: 'Civil Engineering',
//         Fees: "2.31 Lakhs (1stYear Fees)",
//         Application_Date: "10 June - 18 Jun 2025",
//     },

//     {
//         coursename: 'Computer Science and Engineering',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "20 Mar - 18 Apr 2025",
//     },


//     {
//         coursename: 'Mechanical Engineering',
//         Fees: "2.31 Lakhs (1stYear Fees)",
//         Application_Date: "10 June - 18 Jun 2025",
//     },

//     {
//         coursename: 'Chemical Engineering',
//         Fees: "₹2.31 Lakhs (1st Year Fees)",
//         Application_Date: "	19 June - 29 Jul 2025",
//     },

//     {
//         coursename: 'Electrical Engineering',
//         Fees: "₹44,000 (1st Year Fees)",
//         Application_Date: "10 Sept - 25 Oct 2024",
//     },


//     {
//         coursename: 'Industrial & Production Engineering',
//         Fees: "₹2.31 Lakhs (1st Year Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },

//     {
//         coursename: 'Mathematics And Computing',
//         Fees: "80,700 (1stYear Fees)",
//         Application_Date: "20 Mar - 18 Apr 2025",
//     },


//     {
//         coursename: 'Biotechnology And Biochemical Engineering',
//         Fees: "₹3.31 Lakhs (1st Year Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },

//     {
//         coursename: 'Textile Technology',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },
//     {
//         coursename: 'LLM',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },
//     {
//         coursename: 'Engineering Physics',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },
//     {
//         coursename: 'Electrical Engineering [Power And Automation]',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },
//     {
//         coursename: 'Material Science Engineering',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },
//     {
//         coursename: 'Energy Engineering',
//         Fees: "66,700 (1stYear Fees)",
//         Application_Date: "1 Jan - 31 Jan 2025",
//     },



// ];



const Branches = () => {

    const id = useParams();
    const collegeId = id.collegeid
    const courseId = id.courseid
    console.log("iiiiiiiiiiiiiiiiiiiid", collegeId)
    console.log("idddddddddddddddddddd", courseId)

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_streams_by_courseId/${collegeId}/${courseId}`
                );
                const fetchedData = response.data || [];
                setTableData(fetchedData);
                console.log("fetchedData", fetchedData); // Log for debugging
            } catch (error) {
                console.error("Error fetching college data:", error);
            }
        };

        fetchCollegeData();
    }, []);


    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent navigation
        setShowPopup(true);
        document.body.style.overflow = "hidden"; // Disable background scrolling
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        document.body.style.overflow = "auto"; // Enable background scrolling
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        if (showPopup) {
            document.body.classList.add("popup-open");
        } else {
            document.body.classList.remove("popup-open");
        }
    }, [showPopup]);
    return (
        <div>

            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col p-0 m-0">
                            <div className='background_banner cousepage_banner'>
                                <h2>IIT Bombay: Admission 2025, Fees, Courses, Placement</h2>
                                <p>Indian Institute of Technology (IIT) Bombay, one of India’s premier engineering institutes, is renowned for its academic excellence, <br /> cutting-edge research, and top-tier placements. </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <h4>College Details for ID: {id}</h4> */}



                {/* about us section  */}
                {/* <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <div className=''>
                                <h2 className='exploretitle '>About IIT Bombay</h2>
                                <p className='explore_para text-start'>India is home to some of the finest BTech colleges, offering world-class education, cutting-edge research opportunities, and excellent placement records. Based on the latest 2024 rankings, these institutions have been evaluated on parameters like academic excellence, infrastructure, faculty quality, industry exposure, and placement success.</p>
                                <p className='explore_para text-start'>At the undergraduate level, Indian Institute of Technology Bombay offers B.Tech, B.Tech + M.Tech, B.Sc & B.Des whereas at the postgraduate level, it offers M.Tech, M.Tech + PhD, M.Des, MBA, MA + PhD, M.Sc + PhD etc. It also offers PhD in a variety of disciplines. In total, it offers 80+ courses via its 17 departments. IIT Bombay B.Tech is one of the most preferred course offered at the institute. Aspirants must appear for JEE Advanced and JoSAA counselling for admission to its B.Tech program. B.Tech tuition fee at IIT Bombay is INR 1,00,000 per semester for Indian nationals & INR 3,00,000 per semester for Foreign nationals. For admission to its PG courses, the institute accepts GATE, CEED, IIT JAM, CAT Scores. Further, it accepts MET (M.Phil. Entrance Test) for M.Phil. programme. The institute also offers innovative short-term courses, continuing education and distance learning.</p>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* table section  */}
                <div className="container mt-5">
                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr style={{ backgroundColor: '#e5edff' }} className="">
                                            <th scope="col">B.Tech Course</th>
                                            <th scope="col">Fees</th>
                                            <th scope="col">Application Date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tablebody'>
                                        {tableData.map((item, index) => (
                                            <tr key={index}>
                                                <td className=' px-3 fw-semibold'>{item.streamsName}</td>
                                                <td>
                                                    <div >
                                                        <p> &#8377; {item.streamsFee}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <h6>{item.applicationDate}</h6>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <NavLink to="" className=" mt-3 text-decoration-none" onClick={handleOpenPopup}>
                                                            Apply Now <i className="fa fa-angle-double-right"></i>
                                                        </NavLink>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Popup Modal */}
                            {showPopup && (
                                <div className="popup-overlay">
                                    <div className="popup-content">
                                        <button className="close-btn" onClick={handleClosePopup}>
                                            &times;
                                        </button>
                                        <Popupform />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>

                {/* Academic content section  */}
                {/* <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <div className=''>
                                <h2 className='exploretitle '>Academic Excellence</h2>
                                <p className='explore_para text-start'>India Today is one of the leading and well-reputed English news magazines in India published by Living Media India Limited every week. The Marketing & Development Research Associates (MDRA), a marketing and research firm from Delhi and India Today, conducts a college ranking survey. It ranked Indian colleges in fields like Science, Arts, Commerce, Management, Engineering, etc.</p>
                                <p className='explore_para text-start'>Ranked 3rd in the Engineering category by NIRF 2023 and 149th globally by QS World University Rankings 2024, IIT Bombay offers a wide range of undergraduate, postgraduate, and doctoral programs. The institute has 17 departments and provides more than 80 courses, including:</p>


                                <ul className='explore_para text-start'>
                                    <li><strong>Undergraduate Programs:</strong> B.Tech, B.Sc, B.Des, Dual Degree (B.Tech + M.Tech)</li>
                                    <li><strong>Postgraduate Programs:</strong> M.Tech, M.Des, MBA, M.Sc, MA + PhD, M.Tech + PhD</li>
                                    <li><strong>Doctoral Programs:</strong> PhD across various disciplines</li>
                                    <li> <strong>Specialized Courses:</strong>Specialized Courses: Short-term, continuing education, and distance learning programs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* cards images section  */}

            </div>

        </div>
    )
}

export default Branches
