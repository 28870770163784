import React from 'react'

import img1 from '../../Images/exploreicon (1).png'
import img2 from '../../Images/exploreicon (6).png'
import img3 from '../../Images/exploreicon (3).png'
import img4 from '../../Images/exploreicon (4).png'
import img5 from '../../Images/exploreicon (5).png'


import { Link } from 'react-router-dom'

const cardData = [
    {
        id: 1,
        imgSrc: img1,
        title: "JEE Main 2025",
        text: "Joint Entrance Exam Main",
        rankings: [
            "📅 Exam Date: 02 Apr 25 - 09 Apr 25",
            "📝 Application Form: 01 Feb 25 - 25 Feb 25",
            "📢 Result Announce: 12 Apr 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 2,
        imgSrc: img2,
        title: "NEET UG 2025",
        text: "National Eligibility cum Entrance Test",
        rankings: [
            "📅 Exam Date: 05 May 25",
            "📝 Application Form: 01 Mar 25 - 31 Mar 25",
            "📢 Result Announce: 20 Jun 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 3,
        imgSrc: img3,
        title: "GATE 2025",
        text: "Graduate Aptitude Test in Engineering",
        rankings: [
            "📅 Exam Date: 03 Feb 25 - 11 Feb 25",
            "📝 Application Form: 24 Aug 24 - 30 Sep 24",
            "📢 Result Announce: 16 Mar 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 4,
        imgSrc: img4,
        title: "CAT 2025",
        text: "Common Admission Test",
        rankings: [
            "📅 Exam Date: 24 Nov 25",
            "📝 Application Form: 02 Aug 25 - 15 Sep 25",
            "📢 Result Announce: 15 Jan 26"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 5,
        imgSrc: img5,
        title: "CLAT 2025",
        text: "Common Law Admission Test",
        rankings: [
            "📅 Exam Date: 01 Dec 24",
            "📝 Application Form: 01 Jul 24 - 03 Nov 24",
            "📢 Result Announce: 25 Dec 24"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 6,
        imgSrc: img1,
        title: "UPSC CSE 2025",
        text: "Union Public Service Commission - Civil Services Exam",
        rankings: [
            "📅 Prelims: 26 May 25",
            "📅 Mains: 20 Sep 25",
            "📝 Application Form: 01 Feb 25 - 21 Feb 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 7,
        imgSrc: img2,
        title: "BITSAT 2025",
        text: "Birla Institute of Technology and Science Admission Test",
        rankings: [
            "📅 Exam Date: 22 May 25 - 26 May 25",
            "📝 Application Form: 10 Jan 25 - 10 Apr 25",
            "📢 Result Announce: 15 Jun 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 8,
        imgSrc: img3,
        title: "XAT 2025",
        text: "Xavier Aptitude Test",
        rankings: [
            "📅 Exam Date: 05 Jan 25",
            "📝 Application Form: 15 Jul 24 - 30 Nov 24",
            "📢 Result Announce: 20 Jan 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 9,
        imgSrc: img4,
        title: "SRMJEEE 2025",
        text: "SRM Joint Engineering Entrance Exam",
        rankings: [
            "📅 Exam Date: 15 Apr 25 - 20 Apr 25",
            "📝 Application Form: 01 Dec 24 - 31 Mar 25",
            "📢 Result Announce: 01 May 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },

];



const Exam = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>India’s Best Colleges 2025 – Your Path to Success Begins Here! </h2>
                            <p>Explore the Top Colleges in India for 2025! Whether you're looking for the best engineering, medical, management, or law colleges, our updated rankings help you make an informed choice.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-5">
                <div className="row">
                    <h1 className='exploretitle mb-4 '>Entrance Exams In India</h1>
                    {cardData.map((card) => (
                        <div key={card.id} className="col-md-4">
                            <div className="card explore_crd mb-3">

                                <div className=" explore_crd_body">
                                    <div>
                                        <img className="explore_crd_img" src={card.imgSrc} alt="" />
                                    </div>

                                    <div>
                                        <h5 className="explore_crd_title fw-bold">{card.title}</h5>
                                        <p className="explore_crd_text">{card.text}</p>
                                    </div>


                                </div>
                                <ul className=" exp_ul_li">
                                    {card.rankings.map((rank, index) => (
                                        <span key={index} className="">
                                            {rank}
                                        </span>
                                    ))}
                                </ul>
                                <div className="p-3">
                                    <Link to="/contact" className="mybtn p-2">
                                        Apply Now
                                        <i className="fa fa-angle-double-right ms-1"></i>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default Exam
