import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

import img1 from '../Images/exploreicon (1).png';
import img2 from '../Images/exploreicon (6).png';
import img3 from '../Images/exploreicon (3).png';
import img4 from '../Images/exploreicon (4).png';
import img5 from '../Images/exploreicon (5).png';

const cardData = [
    {
        id: 1,
        imgSrc: img1,
        title: "JEE Main 2025",
        rankings: [
            "📅 Exam Date: 02 Apr 25 - 09 Apr 25",
            "📝 Application Form: 01 Feb 25 - 25 Feb 25",
            "📢 Result Announce: 12 Apr 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 2,
        imgSrc: img2,
        title: "NEET UG 2025",
        rankings: [
            "📅 Exam Date: 05 May 25",
            "📝 Application Form: 01 Mar 25 - 31 Mar 25",
            "📢 Result Announce: 20 Jun 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 3,
        imgSrc: img3,
        title: "GATE 2025",
        rankings: [
            "📅 Exam Date: 03 Feb 25 - 11 Feb 25",
            "📝 Application Form: 24 Aug 24 - 30 Sep 24",
            "📢 Result Announce: 16 Mar 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 4,
        imgSrc: img4,
        title: "CAT 2025",
        rankings: [
            "📅 Exam Date: 24 Nov 25",
            "📝 Application Form: 02 Aug 25 - 15 Sep 25",
            "📢 Result Announce: 15 Jan 26"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 5,
        imgSrc: img5,
        title: "CLAT 2025",
        rankings: [
            "📅 Exam Date: 01 Dec 24",
            "📝 Application Form: 01 Jul 24 - 03 Nov 24",
            "📢 Result Announce: 25 Dec 24"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 6,
        imgSrc: img1,
        title: "UPSC CSE 2025",
        rankings: [
            "📅 Prelims: 26 May 25",
            "📅 Mains: 20 Sep 25",
            "📝 Application Form: 01 Feb 25 - 21 Feb 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 7,
        imgSrc: img2,
        title: "BITSAT 2025",
        rankings: [
            "📅 Exam Date: 22 May 25 - 26 May 25",
            "📝 Application Form: 10 Jan 25 - 10 Apr 25",
            "📢 Result Announce: 15 Jun 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 8,
        imgSrc: img3,
        title: "XAT 2025",
        rankings: [
            "📅 Exam Date: 05 Jan 25",
            "📝 Application Form: 15 Jul 24 - 30 Nov 24",
            "📢 Result Announce: 20 Jan 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },
    {
        id: 9,
        imgSrc: img4,
        title: "SRMJEEE 2025",
        rankings: [
            "📅 Exam Date: 15 Apr 25 - 20 Apr 25",
            "📝 Application Form: 01 Dec 24 - 31 Mar 25",
            "📢 Result Announce: 01 May 25"
        ],
        link: "/contact",
        linkText: "Apply Now"
    },

];

const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
};

const Examcomp = () => {
    return (
        <div className="container mt-5">
            <div className="row pb-4">

                <h1 className='exploretitle mb-4'>Entrance Exams In India</h1>
                <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000} keyBoardControl={true} removeArrowOnDeviceType={["tablet", "mobile"]}>
                    {cardData.map((card) => (
                        <div key={card.id} className="card explore_crd my-3 h-100 mx-3 mb-3 ">
                            <div className="explore_crd_body">
                                <img className="explore_crd_img  w-25 h-75" src={card.imgSrc} alt="" />
                                <div className='d-flex'>
                                    <div>
                                        <h5 className="explore_crd_title fw-bold pt-3">{card.title}</h5>
                                    </div>

                                </div>
                            </div>
                            <ul className="exp_ul_li">
                                {card.rankings.map((rank, index) => (
                                    <li key={index}>{rank}</li>
                                ))}
                            </ul>
                            <div className="p-3 text-center">
                                <Link to={card.link} className="mybtn p-2">{card.linkText} <i className="fa fa-angle-double-right ms-1"></i></Link>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Examcomp;
