import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../config/config';

const NavCoursePageList = () => {
    const [college, setCollege] = useState([]);

    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_allNavCourse`
                );
                const fetchedData = response.data.allnavCourse || [];
                setCollege(fetchedData);
                console.log("fetchedData", fetchedData); // Log for debugging
            } catch (error) {
                console.error("Error fetching college data:", error);
            }
        };

        fetchCollegeData();
    }, []);

    return (
        <div>
            <div className="container mt-5 pt-5">
                <div className="row m-2">
                    <div className="col-6">
                        <h1>Nav Course List Page</h1>
                    </div>
                    <div className="col-6">
                        <Link to="/admin/add_navcoursepagelist" className="btn btn-success float-end">
                            Add Nav Course List
                        </Link>
                    </div>
                </div>
                <div className="row p-3">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>College Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {college.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.pageName}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger"
                                            // onClick={() => handleDelete(item._id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NavCoursePageList;
