import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Typewriter } from "react-simple-typewriter";
import './Hslide.css';

// Import your images
import slidimg1 from '../Images/1.png';
import slidimg3 from '../Images/homeban.png';
import slidimg4 from '../Images/homeban (2).png';
import Popupform from "../Popupform/Popupform";

const slideImages = [
    { src: slidimg1, alt: 'First slide' },
    { src: slidimg3, alt: 'Second slide' },
    { src: slidimg4, alt: 'Third slide' }
];

const slideProperties = {
    autoplay: true,
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
};


const Hslide = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = (e) => {
        e.preventDefault(); // Prevent navigation
        setShowPopup(true);
        document.body.style.overflow = "hidden"; // Disable background scrolling
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        document.body.style.overflow = "auto"; // Enable background scrolling
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);


    useEffect(() => {
        if (showPopup) {
            document.body.classList.add("popup-open");
        } else {
            document.body.classList.remove("popup-open");
        }
    }, [showPopup]);

    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const searchRef = useRef(null);

    // Static Data
    const data = [
        // Colleges
        // Indian Colleges
        { type: "college", name: "Indian Institute of Technology Bombay", path: "/college/iit-bombay" },
        { type: "college", name: "Indian Institute of Technology Delhi", path: "/college/iit-delhi" },
        { type: "college", name: "Indian Institute of Technology Madras", path: "/college/iit-madras" },
        { type: "college", name: "Indian Institute of Technology Kanpur", path: "/college/iit-kanpur" },
        { type: "college", name: "Indian Institute of Technology Kharagpur", path: "/college/iit-kharagpur" },
        { type: "college", name: "Indian Institute of Science Bangalore", path: "/college/iisc-bangalore" },
        { type: "college", name: "Jawaharlal Nehru University", path: "/college/jnu" },
        { type: "college", name: "University of Delhi", path: "/college/du" },
        { type: "college", name: "Banaras Hindu University", path: "/college/bhu" },
        { type: "college", name: "Anna University", path: "/college/anna-university" },

        // Exams
        { type: "exam", name: "JEE Advanced", path: "/exam/jee-advanced" },
        { type: "exam", name: "JEE Main", path: "/exam/jee-main" },
        { type: "exam", name: "NEET", path: "/exam/neet" },
        { type: "exam", name: "GATE", path: "/exam/gate" },
        { type: "exam", name: "CAT", path: "/exam/cat" },
        { type: "exam", name: "XAT", path: "/exam/xat" },
        { type: "exam", name: "MAT", path: "/exam/mat" },
        { type: "exam", name: "CMAT", path: "/exam/cmat" },
        { type: "exam", name: "CUET", path: "/exam/cuet" },
        { type: "exam", name: "CLAT", path: "/exam/clat" },
        { type: "exam", name: "UPSC Civil Services", path: "/exam/upsc-cse" },
        { type: "exam", name: "SSC CGL", path: "/exam/ssc-cgl" },
        { type: "exam", name: "IBPS PO", path: "/exam/ibps-po" },
        { type: "exam", name: "RBI Grade B", path: "/exam/rbi-grade-b" },

        // Courses
        { type: "course", name: "Computer Science", path: "/course/cs" },
        { type: "course", name: "Business Management", path: "/course/bm" },
        { type: "course", name: "Mechanical Engineering", path: "/course/mechanical" },
        { type: "course", name: "Electrical Engineering", path: "/course/electrical" },
        { type: "course", name: "Civil Engineering", path: "/course/civil" },
        { type: "course", name: "Artificial Intelligence", path: "/course/ai" },
        { type: "course", name: "Data Science", path: "/course/data-science" },
        { type: "course", name: "Cyber Security", path: "/course/cyber-security" },
        { type: "course", name: "Economics", path: "/course/economics" },
        { type: "course", name: "Psychology", path: "/course/psychology" },
    ];



    // Handle Search Input
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setQuery(value);

        if (!value) {
            setFilteredResults([]);
            return;
        }

        // Filter predefined data
        const results = data.filter((item) =>
            item.name.toLowerCase().includes(value)
        );

        setFilteredResults(results);
        setSelectedIndex(-1);
    };

    // Handle Selection
    const handleSelect = async (item) => {
        if (item.path) {
            // Navigate if path exists
            navigate(item.path);
        } else {
            // Search website content if no direct path
            const foundPage = await searchWebsiteContent(item.name);
            if (foundPage) {
                navigate(foundPage);
            } else {
                alert("No relevant page found.");
            }
        }

        setQuery("");
        setFilteredResults([]);
    };

    // Search Website Content (Simulating for now)
    const searchWebsiteContent = async (term) => {
        const allPages = [
            { url: "/Collegespage", content: "IIT Delhi - Indian Institute of Technology (IITD)" },
            { url: "/Collegespage", content: "Reach out to us for university details" },
            { url: "/Collegespage", content: "IIT Bombay is a premier institute in India" },
        ];

        const match = allPages.find((page) =>
            page.content.toLowerCase().includes(term.toLowerCase())
        );

        return match ? match.url : null;
    };

    // Handle Keyboard Navigation
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setSelectedIndex((prev) =>
                prev < filteredResults.length - 1 ? prev + 1 : prev
            );
        } else if (e.key === "ArrowUp") {
            setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === "Enter" && selectedIndex !== -1) {
            handleSelect(filteredResults[selectedIndex]);
        }
    };

    // Click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setFilteredResults([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div>
            <div className="main_slider_div">
                {/* background images  */}
                <Slide {...slideProperties}>
                    {slideImages.map((slide, index) => (
                        <div className="each-slide-effect" key={index}>
                            <img className="w-100 fade-in-img" src={slide.src} alt={slide.alt} />
                            <div className="overlay"></div>
                        </div>
                    ))}
                </Slide>


                {/* content div  */}
                <div className="contentdiv">
                    <div>
                        <form class="nosubmitcls form-inline searchbar formcls">
                            {/* slider text here  */}
                            <div className="mt-3 Slid_contenttext">
                                <p className="fw-bold">
                                    Find Over{' '}
                                    <span>
                                        <Typewriter
                                            words={["250+ Exams in India.", "Explore Counselling Services Now!"]}
                                            loop={true}
                                            cursor
                                            cursorStyle="|"
                                            typeSpeed={70}
                                            deleteSpeed={50}
                                            delaySpeed={1000}
                                        />
                                    </span>
                                </p>

                                <div class="input-group w-100">
                                    <input type="text"
                                        class=" nosubmitcls  bg-light p-4 form-control" placeholder="Search for colleges, exams, courses and more." aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={query}
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <div class="input-group-prepend">
                                        <button class="searchbtn mybtn" type="">Search</button>
                                    </div>
                                </div>

                                <div className="input-group w-100" ref={searchRef}>

                                    {filteredResults.length > 0 && (
                                        <ul className="search-results">
                                            {filteredResults.map((item, index) => (
                                                <li
                                                    key={index}
                                                    className={selectedIndex === index ? "active" : ""}
                                                    onClick={() => handleSelect(item)}
                                                >
                                                    {item.name} <span className="category">({item.type})</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <div className="container-fluid mt-4  p-0 m-0  ">
                                    <div className="row">
                                        <div className="col-lg-9 pt-1">

                                            <div className="d-flex">
                                                <div className="Recent_Visits">
                                                    <p>Your Recent Visits</p>
                                                </div>
                                                <div>
                                                    <NavLink className="Recent_visit_btn">IIT Delhi, New Delhi</NavLink>
                                                </div>
                                                <div>
                                                    <NavLink className="Recent_visit_btn">CAT</NavLink>
                                                </div>
                                                <div>
                                                    <NavLink className="Recent_visit_btn">IIT Bombay, Mumbai </NavLink>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-3">
                                            <div>
                                                <NavLink to="/contact" className="mybtn counselling_btn" onClick={handleOpenPopup}>
                                                    Need Counselling
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <br /> <br />
                                {/* slogan div here */}
                                <div data-aos="fade-right"
                                    data-aos-duration="1000">
                                    <div className="slogon_div">
                                        <div className="col solgentext">
                                            <div className="Recent_visit_btn p-0">
                                                <p>Unlock Your Potential, Embrace Education</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* this popup container  css in Hslide. css  */}

                                {/* Popup Modal */}
                            </div>

                            {showPopup && (

                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="popup-overlay">
                                                <div className="popup-content">
                                                    <button className="close-btn" onClick={handleClosePopup}>
                                                        &times;
                                                    </button>
                                                    <Popupform />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hslide
