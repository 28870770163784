import React from 'react'

import img1 from '../../Images/1 (1).webp'
import img2 from '../../Images/1 (2).webp'
import img3 from '../../Images/1 (1).webp'
import { Link } from 'react-router-dom'
import CollageTable from '../../../Pages/Collegemianpage/CollageTable'



const Ranking = () => {
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>India’s Best Colleges 2025 – Your Path to Success Begins Here! </h2>
                            <p>Explore the Top Colleges in India for 2025! Whether you're looking for the best engineering, medical, management, or law colleges, our updated rankings help you make an informed choice.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid firstrow" >
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                            <span>  <h6 className='text-secondary mt-1'>India Colleges</h6></span>
                        </div>

                        <p className='toph3 text-start mx-2'>
                            List of Top Colleges in India Based on 2025 Ranking
                        </p>

                    </div>
                </div>


                {/* img row  */}
                <div className="row pt-4 justify-content-center">
                    <div className="col-lg-3">
                        <div>
                            <img src={img1} alt="" className='img-fluid mb-3 ' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img2} alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img3} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>


         <CollageTable/>


        </div>
    )
}

export default Ranking
