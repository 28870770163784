import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';

const AddNavCoursePage = () => {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        pageName: '',
        bannerHeading: '',
        bannerText: '',
        heading: '',
        subHeading: '',
        imgHeading: '',
        imgpara1: '',
        imgpara2: '',
        formHeading: '',
        formSubHeading: '',
        points: [''], // Dynamically add/remove points
        tableHeading: '',
        tableText: '',
    });

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle point input change
    const handlePointChange = (index, e) => {
        const updatedPoints = formData.points.map((point, i) =>
            i === index ? e.target.value : point
        );
        setFormData((prevData) => ({
            ...prevData,
            points: updatedPoints,
        }));
    };

    // Add new point input
    const addPoint = () => {
        setFormData((prevData) => ({
            ...prevData,
            points: [...prevData.points, ''],
        }));
    };

    // Remove point input
    const removePoint = (index) => {
        const updatedPoints = formData.points.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            points: updatedPoints,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${config.API_BASE_URL}/api/admin/add_navcoursespage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), // Send the form data as JSON
            });

            const result = await response.json();
            if (response.status == 201) {
                alert('Course created successfully');
                navigate("/navcoursepagelist")
                console.log(result);
            } else {
                alert('Error creating course');
                console.error(result);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was an error with your request.');
        }
    };


    return (
        <div className="container mt-5">
            <h2>Create New Course</h2>
            <form onSubmit={handleSubmit}>
                {/* Page Name */}
                <div className="mb-3">
                    <label htmlFor="pageName" className="form-label">Page Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="pageName"
                        name="pageName"
                        value={formData.pageName}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Banner Heading */}
                <div className="mb-3">
                    <label htmlFor="bannerHeading" className="form-label">Banner Heading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="bannerHeading"
                        name="bannerHeading"
                        value={formData.bannerHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Banner Text */}
                <div className="mb-3">
                    <label htmlFor="bannerText" className="form-label">Banner Text</label>
                    <textarea
                        className="form-control"
                        id="bannerText"
                        name="bannerText"
                        rows="3"
                        value={formData.bannerText}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>

                {/* Heading */}
                <div className="mb-3">
                    <label htmlFor="heading" className="form-label">Heading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="heading"
                        name="heading"
                        value={formData.heading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Subheading */}
                <div className="mb-3">
                    <label htmlFor="subHeading" className="form-label">Subheading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="subHeading"
                        name="subHeading"
                        value={formData.subHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Image Heading */}
                <div className="mb-3">
                    <label htmlFor="imgHeading" className="form-label">Image Heading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="imgHeading"
                        name="imgHeading"
                        value={formData.imgHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Image Paragraphs */}
                <div className="mb-3">
                    <label htmlFor="imgpara1" className="form-label">Image Paragraph 1</label>
                    <textarea
                        className="form-control"
                        id="imgpara1"
                        name="imgpara1"
                        rows="3"
                        value={formData.imgpara1}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>

                <div className="mb-3">
                    <label htmlFor="imgpara2" className="form-label">Image Paragraph 2</label>
                    <textarea
                        className="form-control"
                        id="imgpara2"
                        name="imgpara2"
                        rows="3"
                        value={formData.imgpara2}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>

                {/* Form Heading */}
                <div className="mb-3">
                    <label htmlFor="formHeading" className="form-label">Form Heading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="formHeading"
                        name="formHeading"
                        value={formData.formHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Form Subheading */}
                <div className="mb-3">
                    <label htmlFor="formSubHeading" className="form-label">Form Subheading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="formSubHeading"
                        name="formSubHeading"
                        value={formData.formSubHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Points */}
                <div className="mb-3">
                    <label htmlFor="points" className="form-label">Points</label>
                    {formData.points.map((point, index) => (
                        <div key={index} className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                name="points[]"
                                value={point}
                                onChange={(e) => handlePointChange(index, e)}
                                required
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => removePoint(index)}
                            >
                                -
                            </button>
                        </div>
                    ))}
                    <button type="button" className="btn btn-outline-secondary" onClick={addPoint}>+</button>
                </div>

                {/* Table Heading */}
                <div className="mb-3">
                    <label htmlFor="tableHeading" className="form-label">Table Heading</label>
                    <input
                        type="text"
                        className="form-control"
                        id="tableHeading"
                        name="tableHeading"
                        value={formData.tableHeading}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Table Text */}
                <div className="mb-3">
                    <label htmlFor="tableText" className="form-label">Table Text</label>
                    <textarea
                        className="form-control"
                        id="tableText"
                        name="tableText"
                        rows="3"
                        value={formData.tableText}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>

                {/* Submit Button */}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default AddNavCoursePage;
