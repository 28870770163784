import React from 'react';
// import contimg from './bg/card-icon-12.png'



import icon1 from "../../Components/Images/con1 (1).png"
import icon2 from "../../Components/Images/con1 (3).png"
import icon3 from "../../Components/Images/con1 (2).png"
import Popupform from '../../Components/Popupform/Popupform';

const Contact = () => {
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>Let’s Connect & Shape Your Future!  </h2>
                            <p>Have questions about your education or career path? We’re here to help! Reach out to <br />our team for expert guidance and personalized support.</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col text-center">
                        <h1 className='exploretitle text-center'>Get in Touch with Us</h1>
                        <p className='explore_para'>We’re here to help! Whether you have questions about our services, need guidance on admissions, or simply want to know more about us, feel free to reach out. Our team is ready to assist you with any inquiries and ensure you have a smooth experience.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        {/* card div start */}
                        <div className=''>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card m-3 text-center rounded-3 border-0 shadow p-4 cont_card">
                                        <img src={icon1} alt="" className='icon_img m-auto' />
                                        <h5 className='contact_crd_title mb-3'>Contact Us</h5>
                                        <p className="m-0">
                                            <a href="tel:+919773741010" className="text-decoration-none text-dark">
                                                +91 977-374-1010
                                            </a>
                                        </p>
                                        {/* <p className=' m-0'>+222 222 222 333</p> */}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="card text-center m-3 rounded-3 border-0 shadow p-4 cont_card">

                                        <img src={icon2} alt="" className='icon_img  m-auto' />
                                        <h5 className='contact_crd_title mb-3'>Our Email Address</h5>
                                        <p className="m-0">
                                            <a href="mailto:Info@getfasteducation.in" className="text-decoration-none text-dark">
                                                Info@getfasteducation.in
                                            </a>
                                        </p>

                                        {/* <p className=' m-0'>example@gmail.com</p> */}

                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="card text-center m-3 rounded-3 border-0 shadow p-4 cont_card">
                                        <img src={icon3} alt="" className='icon_img  m-auto' />
                                        <h5 className='contact_crd_title mb-3'>Our Location</h5>

                                        <p className='m-0'>536/2 Main Road, Near Shalimar Palace, Burari, Delhi - 110084</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* card div end */}

                    </div>
                </div>
            </div>


            <Popupform />

            {/* MAP CONTAINER START  */}
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <div className='mt-3'>
                            <iframe id="iframeid" width="100%" height="500" title="/" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2860.616432519658!2d77.1987645!3d28.739459000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQ0JzIyLjEiTiA3N8KwMTEnNTUuNiJF!5e1!3m2!1sen!2sin!4v1739450111854!5m2!1sen!2sin"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* MAP CONTAINER START  */}
        </div >
    )
}

export default Contact
