import { useState } from "react";
import { NavLink } from "react-router-dom";



import img1 from './images/col1 (1).png';
import img2 from './images/col1 (2).png';
import img3 from './images/col1 (3).png';
import img4 from './images/col1 (4).png';
import img5 from './images/col1 (5).png';
import img6 from './images/col1 (6).png';
import img7 from './images/col1 (7).png';






// college card data 
const college = [
    { link: 'BTech colleges in Coimbatore ', img: img1, rank: "Ranked 11 out of 80 TOI" },
    { link: 'BTech colleges in Tamilnadu ', img: img2, rank: "Ranked 150 out of 1400S" },
    { link: 'BTech colleges in Chennai', img: img3, rank: "Ranked 609 out of 1400S" },
    { link: 'BTech colleges in Pune', img: img4, rank: "Ranked 95 out of 1400S" },
    { link: 'BTech colleges in Banglore', img: img5, rank: "Ranked 150 out of 1400S" },
    { link: 'BTech colleges in India', img: img6, rank: "Ranked 100 out of 1400S" },
    { link: 'BTech colleges in Mumbai ', img: img7, rank: "Ranked 120 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 1200 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 259 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 854 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 150 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 800 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 360 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 250 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 200 out of 1400S" },
    { link: 'BTech colleges in India', img: img7, rank: "Ranked 150 out of 1400S" },
];


const Colleges = () => {

    const [visibleCount, setVisibleCount] = useState(6); // Show 8 cards initially

    const loadMoreCards = () => {
        setVisibleCount((prevCount) => prevCount + 4); // Load 4 more cards
    };
    return (
        <div>

            {/* heading container start  */}
            {/* this container css in program.css page  */}

            <div className="container mt-5 pt-5">
                <div className="row ">
                    <div className="col">
                        <div className=''>
                            {/* heading */}
                            <h1 className='exploretitle fs-1'>
                                Top Universities/Colleges
                            </h1>

                            <p className='explore_para text-start'>Discover a curated list of prestigious universities and colleges that set the standard for global education. From cutting-edge research facilities to vibrant campus life, these institutions offer everything you need to excel academically and personally.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* heading container end  */}

            {/* all colleges cards   */}
            <div className="container">
                <div className="row pt-3 colegcontainer">
                    {college.slice(0, visibleCount).map((item, index) => (
                        <div key={index} className="col-md-4 college_col">
                            <div className="card mt-3">
                                <img
                                    src={item.img}
                                    className="card-img-top"
                                    alt={item.name}
                                />
                                <div className="">
                                    <p className="college_rank">
                                        {item.rank}
                                    </p>

                                    <ul className="college_ul">
                                        <li className="college_ul_li">View all Courses And Fees</li>
                                        <li className="college_ul_li">Compare</li>
                                        <li className="college_ul_li">Download Brochure</li>
                                    </ul>
                                    <div className="pt-2">
                                        <NavLink to="/contact" className="fw-semibold card-link colcardlink">
                                            {item.link} <i className="fa fa-angle-double-right"></i>
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            {/* Load More Button */}
            {visibleCount < college.length && (
                <div className="text-center">
                    <button
                        className="mybtn mt-4"
                        onClick={loadMoreCards}>
                        Load More Colleges
                    </button>
                </div>
            )}
        </div>








    )
}

export default Colleges
