import React from 'react'
import { Link } from 'react-router-dom';
import img1 from '../../../Images/mtech2.png'
import img2 from '../../../Images/btech2.png'
import { NavLink } from 'react-bootstrap';

const Chemistry = () => {
    return (
        <div>
            <div className="container-fluid firstrow" >
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                            <span>  <h6 className='text-secondary mt-1'>B.Sc Colleges</h6></span>
                        </div>
                        <p className='fw-semibold toph3 text-start fs-4 mx-2'>
                            List of B.Sc in Chemistry Colleges in India Based on 2024 Ranking
                        </p>
                    </div>
                </div>

                {/* img row  */}
                <div>
                    <div className="row p-4">
                        <div className="col-lg-2"></div>

                        <div className="col-lg-4">
                            <div>
                                <img src={img2} alt="" className='img-fluid mb-3 ' />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <img src={img1} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-2"></div>

                    </div>


                    <div>
                        <div className="row bg-light">
                            <div className="col p-5">
                                <div className="card firstrow border-0  p-3 ">
                                    <h6 className='fw-semibold'>Latest Notifications</h6>
                                    <p className='text-danger'>28 July, 2024 : <NavLink>CUET UG 2024 Re-Test Result Out; Check Scorecard Here</NavLink> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


        </div>
    )
}

export default Chemistry