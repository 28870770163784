import React from 'react'
import logoimg from '../Images/GET-FAST-EDUCATION-LOGO-1.png'
import { Link } from 'react-router-dom'


import icon1 from '../Images/socialicon (1).png'
import icon2 from '../Images/socialicon (2).png'
import icon3 from '../Images/socialicon (3).png'
import icon4 from '../Images/socialicon (4).png'

const Footer = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="text-center">
                            <h4 className="fw-bold exploretitle">Subscribe to our newsletter</h4>
                            <p className="mb-4 fs-6 explore_para">
                                Get College Notifications, Exam Notifications, and News Updates
                            </p>
                            <form action="">
                                <div className="row g-3">
                                    <div className="col-12 col-md-6 col-lg-3">
                                        <div className="input-field">
                                            <input required autoComplete="off" type="text" name="email" id="email" className="" />
                                            <label htmlFor="email" className="form-label">Enter your Email</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3">
                                        <div className="input-field">
                                            <input required autoComplete="off" type="text" name="email" id="email" className="" />
                                            <label htmlFor="email" className="form-label">Enter your Phone</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3">
                                        <div className="input-field">
                                            <select required name="course" id="course" className="">
                                                <option value="" disabled selected>Select Course</option>
                                                <option value="btech">BTech</option>
                                                <option value="mtech">MTech</option>
                                                <option value="mba">MBA</option>
                                                <option value="bca">BCA</option>
                                                <option value="msc">MSc</option>
                                                <option value="ma">MA</option>
                                                <option value="phd">PhD</option>
                                                <option value="bba">BBA</option>
                                                <option value="mca">MCA</option>
                                                <option value="bsc">BSc</option>
                                                <option value="ba">BA</option>
                                                <option value="bcom">BCom</option>
                                                <option value="mcom">MCom</option>
                                                <option value="bds">BDS</option>
                                                <option value="mbbs">MBBS</option>
                                                <option value="law">LLB</option>
                                                <option value="bfa">BFA</option>
                                                <option value="mfa">MFA</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-3 text-center">
                                        <button className="mybtn w-100">Subscribe</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid mt-5 footerB_G">
                <div className="row">
                    <div className="col">
                        <div class="footer footerB_G">
                            <section class="py-4 py-md-5 py-xl-8 border-top ">
                                <div class="container-fluid overflow-hidden">
                                    <div class="row gy-lg-0 justify-content-xl-between">

                                        {/* col one  */}
                                        <div class="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div class="">
                                                <h4 class=" mb-4 fs-4 exploretitle">Top colleges</h4>
                                                <ul class="list-unstyled ">
                                                    <li class="mb-2">
                                                        <Link to='/' class=" footer_link text-decoration-none"> <i className='fa fa-angle-double-right me-2'></i>M.B.A</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>B.Tech/B.E</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>MCA</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>BCA</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>M. Tech</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>MA</Link>
                                                    </li>

                                                </ul>
                                            </div>


                                        </div>

                                        {/* Col two  */}
                                        <div class="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div class="">
                                                <h4 class=" mb-4 fs-4 exploretitle">Top Exam</h4>
                                                <ul class="list-unstyled ">
                                                    <li class="mb-2">
                                                        <Link to='/' class=" footer_link text-decoration-none"> <i className='fa fa-angle-double-right me-2'></i> CAT</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>GATE</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Jee-Main</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>NEET</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>XAT</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>CLAT</Link>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-3 col-lg-3 col-xl-2">
                                            <div class="">
                                                <h4 class=" mb-4 fs-4 exploretitle">Top Universities</h4>
                                                <ul class="list-unstyled">
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none "> <i className='fa fa-angle-double-right me-2'></i>Engineering</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Management</Link>
                                                    </li>

                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Medical</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Law</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Commerce</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Science</Link>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>



                                        <div class="col-12 col-md-3 col-lg-3 col-xl-2">
                                            <div class="">
                                                <h4 class=" mb-4 fs-4 exploretitle">Study abroad</h4>
                                                <ul class="list-unstyled">
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none "> <i className='fa fa-angle-double-right me-2'></i>Canada</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>USA</Link>
                                                    </li>

                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i> UK</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>UAE </Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Australia</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Germany</Link>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>


                                        <div class="col-12 col-md-3 col-lg-3 col-xl-2">
                                            <div class="">
                                                <h4 class=" mb-4 fs-4 exploretitle">Board Exams</h4>
                                                <ul class="list-unstyled">
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none "> <i className='fa fa-angle-double-right me-2'></i>CBSE Class 12</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>Class 12th Results</Link>
                                                    </li>

                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i> Class 12th Syllabus</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i> Class 12th Exam </Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>CBSE Class 10</Link>
                                                    </li>
                                                    <li class="mb-2">
                                                        <Link to='/' class="footer_link text-decoration-none">  <i className='fa fa-angle-double-right me-2'></i>CBSE 10th Exam</Link>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>



                            {/* other links container */}
                            <div className="container-fluid">
                                <div className="row border-top pt-3">
                                    {/* Other Links Section */}
                                    <div className="col-lg-10 col-md-9 col-sm-12 mb-3">
                                        <p className='otherlink_text'>Other Links</p>
                                        <ul className="nav-links d-flex flex-wrap p-0">
                                            <li><Link to="/About" className='otherlink_text_li'>About Getfast Education</Link></li>
                                            <li><Link to="/contact" className='otherlink_text_li'>Contact Us</Link></li>
                                            <li><Link to="/Blog" className='otherlink_text_li'>Blogs</Link></li>
                                            <li><Link to="/terms&condition" className='otherlink_text_li'>Terms & Conditions</Link></li>
                                            <li><Link to="/Privacy-Policy" className='otherlink_text_li'>Privacy Policy</Link></li>
                                        </ul>
                                    </div>

                                    {/* Social Icons Section */}
                                    <div className="col-lg-2 col-md-3 col-sm-12 text-md-end text-sm-center pt-3">
                                        <div className="social-icons d-flex justify-content-md-end justify-content-sm-center gap-3">
                                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                                <img src={icon1} alt="LinkedIn" className="footer-icon" />
                                            </a>
                                            <a href="https://www.instagram.com/get_fasteducation/?hl=en" target="_blank" rel="noopener noreferrer">
                                                <img src={icon2} alt="Instagram" className="footer-icon" />
                                            </a>
                                            <a href="https://www.facebook.com/getfasteducation" target="_blank" rel="noopener noreferrer">
                                                <img src={icon3} alt="Facebook" className="footer-icon" />
                                            </a>
                                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                                <img src={icon4} alt="Twitter" className="footer-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <section className=' border-top pt-3'>
                                <div className='container-fluid'>
                                    <div className="row">
                                        <div className="col">
                                            <div>
                                                <h4 className='otherlink_text'>Disclaimer </h4>
                                                <p className='dis_text'>Getfast Education provides educational information, resources, and university details for informational purposes only. While we strive to ensure the accuracy and reliability of the content on our platform, we do not guarantee its completeness, timeliness, or accuracy. Users are advised to verify all details directly with official university websites before making any decisions.Our website may include links to third-party websites, including universities, educational institutions, and external resources. These links are provided for convenience and informational purposes only. Getfast Education does not control, endorse, or take responsibility for the content, privacy policies, or practices of any third-party websites. Users should review their respective policies before interacting with them.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>


                            {/* <!-- Copyright - Section --> */}
                            <div className="container-fluid p-3">
                                <div className="row border-top align-items-center">
                                    <div className="col-md-6 d-flex flex-column flex-md-row align-items-center">
                                        <Link to="/">
                                            <img src={logoimg} alt="Logo" className="footerlogo img-fluid" />
                                        </Link>
                                        <span className="copyright mt-2 mt-md-0 mx-md-3 text-center text-md-start">
                                            &copy; 2025 Get Fast Education, Inc. All Rights Reserved.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* copyright end  */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
