import React from 'react'
import { NavLink } from 'react-router-dom'

import img1 from '../../Images/col_icon1.webp'
import img2 from '../../Images/col_icon2.webp'
import img3 from '../../Images/col_icon3.webp'
import img4 from '../../Images/col_icon4.webp'
import img5 from '../../Images/col_icon5.webp'
import img6 from '../../Images/col_icon6.webp'

const Studyabroad = () => {
    return (
        <div>


            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>Find Study Abroad Universities</h2>
                            <p>Expert Counselling with 50% Off on Student Visa Fees, Choose from Extensive Colleges and Course options.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col text-center"> {/* Center content */}
                        <NavLink to="/contact" className='mybtn rounded-pill p-3'>Apply Now</NavLink>
                        <NavLink to="/contact" className='mybtn bg-primary rounded-pill  p-3 ms-2'>Shortlist College</NavLink>
                    </div>
                </div>



                <div className="container mt-5 pt-5">
                    <div className="row ">
                        <div className="col">
                            <div className='text-center'>
                                {/* heading */}
                                <h1 className='exploretitle fs-1'>
                                    Apply to 500+ Universities
                                </h1>

                                <p className='explore_para'>   Get Exclusive Discounts on Application & VISA Fees
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">College</th>
                                    <th scope="col">Ranking</th>
                                    <th scope="col">Streams</th>
                                </tr>
                            </thead>
                            <tbody className='tablebody'>
                                <tr>
                                    <td className='fw-bold'>
                                        <img src={img1} className='me-4' alt="" />Jawaharlal Nehru University - [JNU], New Delhi</td>
                                    <td> 1 out of 44</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img2} className='me-4' alt="" />Symbiosis International University - [SIU], Pune</td>
                                    <td>1 out of 73</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>
                                        <img src={img3} className='me-4' alt="" />Amity University, Noida</td>
                                    <td>2 out of 73</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img4} className='me-4' alt="" />Delhi University - [DU], New Delhi</td>
                                    <td>2 out of 44</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>
                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img5} className='me-4' alt="" />Aligarh Muslim University - [AMU], Aligarh</td>
                                    <td>3 out of 44</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>

                                <tr>

                                    <td className='fw-bold'>
                                        <img src={img6} className='me-4' alt="" />K L University - [KLU], Guntur</td>
                                    <td>3 out of 73</td>
                                    <td>    <NavLink to="/contact" className='mybtn p-2'>Apply Now</NavLink></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Studyabroad
