import { Carddata } from '../Cardslider/Carddata';
import React, { useRef } from 'react'
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
// import { NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Cardslider = () => {
    const carouselRef = useRef(null);
    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    return (
        <div>
            <div className="container topcontainer">
                <div className="row">
                    <div className="col">
                        {/* <div data-aos="fade-up"
                            data-aos-duration="1500"> */}
                        <h1 className='exploretitle '>Select Your Study Goal</h1>
                        <p className='explore_para text-start'>Whether you're aiming to boost your skills, prepare for exams, or explore new knowledge areas, we've got you covered. Select your study goal and start your journey towards success:</p>

                        {/* </div> */}

                        <Carousel ref={carouselRef} responsive={responsive} arrows={false}>

                            {/* card data in card.js  */}
                            {Carddata.map(({ title, img, heading, ul1, ul2, ul3, index }) => (
                                <div className="border main_card rounded-3 mt-3 pb-3" key={index}>
                                    <div>
                                        <div className="p-0">
                                            <div className="row mt-3 crdsld">
                                                <div className="col-lg-4 m-auto">
                                                    <img src={img} alt="iconimg" className=' mx-3 iconimg' />
                                                </div>
                                                <div className="col-lg-8">
                                                    <h4 className="text-dark fs-5 fw-semibold mt-2">{title}</h4>
                                                    <p className="m-0 text-secondary" style={{ fontSize: '15px' }}>
                                                        {heading}
                                                    </p>
                                                </div>
                                            </div>


                                            <div>
                                                <ul className="list-group list-group-flush p-3" style={{ fontSize: '15px' }}>
                                                    <li className="list-group-item m-0 p-0">
                                                        <Link to={ul1.link} className="carditem nav-link">{ul1.name}</Link>
                                                    </li>
                                                    <li className="list-group-item m-0 p-0">
                                                        <Link to={ul2.link} className="carditem nav-link">{ul2.name}</Link>
                                                    </li>
                                                    <li className="list-group-item m-0 p-0">
                                                        <Link to={ul3.link} className="carditem nav-link">{ul3.name}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        {/* Custom Navigation Buttons */}
                        <button className="fa fa-angle-left custom-prev" onClick={handlePrev} aria-label="Previous">  </button>
                        <button className=" fa fa-angle-right custom-next" onClick={handleNext} aria-label="Next">   </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cardslider
