import React from 'react'
import { NavLink } from "react-router-dom";

import img1 from '../../Colleges/images/col1 (1).png';
import img2 from '../../Colleges/images/col1 (2).png';
import img3 from '../../Colleges/images/col1 (3).png';
import img4 from '../../Colleges/images/col1 (4).png';
import img5 from '../../Colleges/images/col1 (5).png';
import img6 from '../../Colleges/images/col1 (6).png';
import img7 from '../../Colleges/images/col1 (7).png';


// college card data 
const college = [
    { link: 'BTech colleges in Coimbatore ', img: img1, course: "B.Sc | BA", description: "Explore top engineering colleges in Coimbatore offering excellent academics and placements." },
    { link: 'BTech colleges in Tamilnadu ', img: img2, course: " BE/B.Tech | M.Phil/Ph.D in Engineering", description: "Find the best BTech colleges across Tamilnadu with industry-aligned courses." },
    { link: 'BTech colleges in Chennai', img: img3, course: " B.Arch | B.Des", description: "Discover Chennai's top-ranked BTech institutions offering diverse engineering programs." },
    { link: 'BTech colleges in Pune', img: img4, course: " B.Arch | B.Com", description: "Pune's engineering colleges are known for academic excellence and innovation." },
    { link: 'BTech colleges in Banglore', img: img5, course: "B.Com | B.Ed ", description: "Explore Bangalore’s top BTech colleges with strong industry connections." },
    { link: 'BTech colleges in India', img: img6, course: "M.Phil/Ph.D in Engineering ", description: "Top BTech colleges across India offering cutting-edge education and research." },
    { link: 'BTech colleges in Mumbai ', img: img7, course: "MBA/PGDM ", description: "Mumbai's leading engineering colleges with high placement opportunities." },

];
const Admissionalert = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className='background_banner blog_banner'>
                            <h2>Get Admission in Best colleges</h2>
                            <p>India is home to some of the finest educational institutions, renowned for their academic excellence, infrastructure, and placement opportunities</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container mb-5">
                <div className="row pt-3 colegcontainer">
                    {college.map((item, index) => (
                        <div key={index} className="col-md-4 mt-3 college_col">
                            <div className="card h-100 ">
                                <img src={item.img} className="card-img-top" alt={item.link} />
                                <div className="card-body">
                                    <p className="college_rank text-dark fw-bold">{item.course}</p>
                                    <p className="college_description">{item.description}</p>
                                    <div className="pt-2">
                                        <NavLink className="fw-semibold card-link colcardlink mx-0 px-0 ">{item.link} <i className="fa fa-angle-double-right"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>



        </div>
    )
}

export default Admissionalert
