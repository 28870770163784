import React, { useState } from 'react';
import logoimg from '../Images/GET-FAST-EDUCATION-LOGO-1.png';
import reviewimg from '../Images/reviewimg.png';

const Login = () => {
  const [showBox, setShowBox] = useState(false);

  const toggleBox = () => {
    setShowBox(!showBox);
  };

  return (
    <div>
      <div className="login">
        <button className="loginbtn" onClick={toggleBox}>
          <div className='border p-2 rounded-pill'>
            <i className='fa fa-bars me-3 text-light'></i>
            <i className='fa fa-user text-light'></i>
          </div>
        </button>
        {/* Login content box */}
        <div className={`dropdown-box ${showBox ? 'show' : ''}`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-5 col-sm-6 mb-3 text-center">
                <img src={logoimg} alt="logo" className='img-fluid loginimg' />
              </div>
              <div className="col-lg-8 col-md-7 col-sm-6 mb-3">
                <p className=''>Welcome To GetFast Education</p>
              </div>
            </div>

            {/* Login row */}
            <div className="row">
              <div className="col-12">
                {/* Form */}
                <form className="nosubmit form-inline mt-3">
                  <input className="nosubmit form-control w-100" type="search" placeholder="Engineering, Pune" />
                </form>
                {/* Login/Register button */}
                <button className='mybtn w-100 mt-3 p-2'>Login/Register</button>
              </div>
            </div>

            {/* Image row */}
            <div className="row mt-3">
              <div className="col-12">
                <img src={reviewimg} alt="Review" className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
