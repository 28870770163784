// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Aos from 'aos';




// main components import here 
// import Tophead from './Components/Header/Tophnead';
// import Nav from './Components/Navbar/Nav';
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog/Blog';
import Contact from './Pages/Contact/Contact';
// import Footer from './Components/Footer/Footer';

// course pages import

// Betch pages import  
import Btech from './Components/Course/Coursepages/Btech/Btech';
import Jaipur from './Components/Course/Coursepages/Btech/Jaipur';
import Mumbai from './Components/Course/Coursepages/Btech/Mumbai';

// MTech pages import 
import Mtech from './Components/Course/Coursepages/Mtech/Mtech';
import Electronics from './Components/Course/Coursepages/Mtech/Electronics';
import CivilEng from './Components/Course/Coursepages/Mtech/CivilEng';

// MBBS section 
import Mbbs from './Components/Course/Coursepages/Mbbs/Mbbs';
import Chennai from './Components/Course/Coursepages/Mbbs/Chennai';

//  BCOM pages 
import Bcom from './Components/Course/Coursepages/Bcom/Bcom';
import Bcom2 from './Components/Course/Coursepages/Bcom/Bcom2';

// BSE pages 
import Math from './Components/Course/Coursepages/Bsc/Match';
import Physics from './Components/Course/Coursepages/Bsc/Physics';
import Chemistry from './Components/Course/Coursepages/Bsc/Chemisrty';

import Bsenursing from './Components/Course/Coursepages/Bsenursing';
import Ba from './Components/Course/Coursepages/Ba';
import Collegemainpage from './Pages/Collegemianpage/Collegemainpage';
import Aboutus from './Pages/Aboutus/Aboutus';
import Coursemainpage from './Pages/Collegemianpage/Coursemainpage';
import Branches from './Pages/Collegemianpage/Branches';
import Term from './Pages/Termcondition/Term';
import Toptoscroll from './Components/Toptoscroll/Toptoscroll';
import Weblayout from './Components/Weblayout';
import Admin_Layout from './Admin/Admin_Layout';
import AdminLogin from './Admin/AdminLogin';
import StreamList from './Admin/StreamList';
import AddStreamForm from './Admin/AddStreamForm';
import CollegePage from './Admin/CollegePage';
import AddCollegePage from './Admin/AddCollegePage';
import NavCoursePageList from './Admin/NavCoursePageList';
import AddNavCoursePage from './Admin/AddNavCoursePageList';

import Reviewcomp from './Components/Reviewcomp/Reviewcomp';
import Topuniversities from './Components/Header/Explorepages/Topuniversities';
import Admissionalert from './Components/Header/Explorepages/Admissionalert';
import Ecplorecourse from './Components/Header/Explorepages/Ecplorecourse';
import Studyabroad from './Components/Header/Explorepages/Studyabroad';
import Institutes from './Components/Header/Explorepages/Institutes';
import Scholarship from './Components/Header/Explorepages/Scholarship';
import Engineering from './Components/Cardslider/Innerpages/Engineering';
import Ranking from './Components/Explore/Innerpages/Ranking';
import Exam from './Components/Explore/Innerpages/Exam';
import Collegepre from './Components/Explore/Innerpages/Collegepre';
import Innerpage from './Components/Collegranking/Innerpage';
import Courseprogram from './Components/Program/Innerpageprog/Courseprogram';
import Innerpagetopstudy from './Components/TopstudyPlaces/Innerpages/Innerpagetopstudy';
import Privacypolicy from './Pages/Privacypolicy/Privacypolicy';

// import Loader from './Components/Loader/Loader';



function App() {

  // scroll to top on page load
  // scroll button .......------------------------------
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2000) { // Adjust the scroll position as needed
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  // // loader code ----------------------------------
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  // // loader code end --------------------------------


  useEffect(() => {
    Aos.init();
  }, [])


  return (
    <>
      <div>
        {/* {loading ? <Loader /> : <div> */}

        {/* Router  */}
        <BrowserRouter >
          <Toptoscroll />
          <Routes>
            {/* Navbar All  Pages   */}
            <Route path='/' element={
              <Weblayout>
                <Home />
              </Weblayout>
            }></Route>
            <Route path='/About' element={
              <Weblayout>
                <Aboutus />
              </Weblayout>
            }></Route>
            <Route path='/Blog' element={
              <Weblayout>
                <Blog />
              </Weblayout>
            }></Route>
            <Route path='/contact' element={
              <Weblayout>
                <Contact />
              </Weblayout>
            }></Route>
            <Route path='/terms&condition' element={
              <Weblayout>
                <Term />
              </Weblayout>
            }></Route>

            <Route path='/Privacy-Policy' element={
              <Weblayout>
                <Privacypolicy />
              </Weblayout>
            }></Route>


            <Route path='/review' element={
              <Weblayout>
                <Reviewcomp />
              </Weblayout>
            }></Route>


            <Route path='/Institutes' element={
              <Weblayout>
                <Institutes />
              </Weblayout>
            }></Route>


            <Route path='/Scholarship' element={
              <Weblayout>
                <Scholarship />
              </Weblayout>
            }></Route>


            {/* Nav Explore  section pages here */}

            <Route path='/TopUniversitiesandColleges' element={
              <Weblayout>
                <Topuniversities />
              </Weblayout>
            }></Route>

            <Route path='/Admissionalert' element={
              <Weblayout>
                <Admissionalert />
              </Weblayout>
            }></Route>


            <Route path='/TopCourse' element={
              <Weblayout>
                <Ecplorecourse />
              </Weblayout>
            }></Route>

            <Route path='/StudyAbroad' element={
              <Weblayout>
                <Studyabroad />
              </Weblayout>
            }></Route>






            {/* Select Your Study Goal Card slider pages link here  */}

            <Route path='/Engineering' element={
              <Weblayout>
                <Engineering />
              </Weblayout>
            }></Route>



            {/* Explore Programs  Component here  */}

            <Route path='/CollegRanking' element={
              <Weblayout>
                <Ranking />
              </Weblayout>
            }></Route>


            <Route path='/Exam' element={
              <Weblayout>
                <Exam />
              </Weblayout>
            }></Route>

            <Route path='/college-predictor' element={
              <Weblayout>
                <Collegepre />
              </Weblayout>
            }></Route>


            <Route path='/College-Ranking' element={
              <Weblayout>
                <Innerpage />
              </Weblayout>
            }></Route>



            {/* Find Your Perfect University Match Online */}
            {/* our programe section */}
            <Route path='/Programe-course' element={
              <Weblayout>
                <Courseprogram />
              </Weblayout>
            }></Route>

            <Route path='/TopStudy-colleges' element={
              <Weblayout>
                <Innerpagetopstudy />
              </Weblayout>
            }></Route>



            {/* course finder path  */}

            {/* <Route path='/finder' element={<Coursefinder />}></Route> */}


            {/* allcourse router start all pages */}
            {/* Betch section */}
            <Route path='/Delhi' element={
              <Weblayout>
                <Btech />
              </Weblayout>
            }></Route>
            <Route path='/jaipur' element={
              <Weblayout>
                <Jaipur />
              </Weblayout>

            }></Route>
            <Route path='/mumbai' element={
              <Weblayout>
                <Mumbai />
              </Weblayout>
            }></Route>

            {/* Mtech section  */}
            <Route path='/CS' element={
              <Weblayout>
                <Mtech />
              </Weblayout>
            }></Route>
            <Route path='/Electronic' element={
              <Weblayout>
                <Electronics />
              </Weblayout>
            }></Route>
            <Route path='/Civil' element={
              <Weblayout>
                <CivilEng />
              </Weblayout>
            }></Route>

            {/* MBBS Section  */}
            <Route path='/mbbspage' element={
              <Weblayout>
                <Mbbs />
              </Weblayout>
            }></Route>
            <Route path='/chennai' element={
              <Weblayout>
                <Chennai />
              </Weblayout>
            }></Route>


            {/* Bcom pages  */}
            <Route path='/Accounting' element={
              <Weblayout>
                <Bcom />
              </Weblayout>
            }></Route>
            <Route path='/Finance' element={
              <Weblayout>
                <Bcom2 />
              </Weblayout>
            }></Route>

            {/* Bse Pages  */}
            <Route path='/Math' element={
              <Weblayout>
                <Math />
              </Weblayout>
            }></Route>
            <Route path='/Physics' element={
              <Weblayout>
                <Physics />
              </Weblayout>
            }></Route>
            <Route path='/Chemistry' element={
              <Weblayout>
                <Chemistry />
              </Weblayout>
            }></Route>

            <Route path='/bseNpage' element={
              <Weblayout>
                <Bsenursing />
              </Weblayout>
            }></Route>
            <Route path='/bapage' element={
              <Weblayout>
                <Ba />
              </Weblayout>
            }></Route>


            {/* main course page route here  */}

            <Route path='/Collegespage/:id' element={
              <Weblayout>
                <Collegemainpage />
              </Weblayout>
            }></Route>

            <Route path="/college/:id" element={
              <Weblayout>
                <Coursemainpage />
              </Weblayout>
            } />

            <Route path="/branch/:collegeid/:courseid" element={
              <Weblayout>
                <Branches />
              </Weblayout>
            } />

            <Route path="/admin" element={
              <>
                <AdminLogin />
              </>
            } />
            <Route path="/admin/dashboard" element={
              <Admin_Layout>

              </Admin_Layout>
            } />

            <Route path="/admin/collegepage" element={
              <Admin_Layout>
                <CollegePage />
              </Admin_Layout>
            } />

            <Route path="/admin/navcoursepagelist" element={
              <Admin_Layout>
                <NavCoursePageList />
              </Admin_Layout>
            } />

            <Route path="/admin/add_navcoursepagelist" element={
              <Admin_Layout>
                <AddNavCoursePage />
              </Admin_Layout>
            } />

            <Route path="/admin/add_collegepage" element={
              <Admin_Layout>
                <AddCollegePage />
              </Admin_Layout>
            } />

            <Route path="/admin/stream" element={
              <Admin_Layout>
                <StreamList />
              </Admin_Layout>
            } />

            <Route path="/admin/add_stream" element={
              <Admin_Layout>
                <AddStreamForm />
              </Admin_Layout>
            } />

          </Routes>
        </BrowserRouter>



        {/* css for scroll top  */}
        <i className="fa fa-arrow-up fs-1 scroll-to-top"
          onClick={scrollToTop}
          style={{
            display: visible ? 'block' : 'none',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            color: '#ff7900',
            padding: '10px 20px',
            cursor: 'pointer'
          }}>
        </i>
      </div>
      {/* }  */}
      {/* </div> */}


    </>
  );
}

export default App;
