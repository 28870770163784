import React from 'react'
import { Link } from 'react-router-dom'

import img1 from '../../Images/1 (1).webp'
import img2 from '../../Images/1 (2).webp'
import img3 from '../../Images/1 (1).webp'


import preimg1 from '../../Images/col_icon1.webp'
import preimg2 from '../../Images/col_icon2.webp'
import preimg3 from '../../Images/col_icon3.webp'
import preimg4 from '../../Images/col_icon4.webp'
import preimg5 from '../../Images/col_icon5.webp'
import preimg6 from '../../Images/col_icon6.webp'
import preimg7 from '../../Images/col_logo4.webp'
import preimg8 from '../../Images/col_logo5.webp'
import preimg9 from '../../Images/col_logo6.webp'



const cardData = [
    {
        id: 1,
        imgSrc: preimg1,
        title: "JEE Main 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 02 Apr 25 - 09 Apr 25",
            "📝 Application Form: 01 Feb 25 - 25 Feb 25",
            "📢 Result Announce: 12 Apr 25"
        ],
        linkText: "Apply Now"
    },
    {
        id: 2,
        imgSrc: preimg2,
        title: "NEET UG 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 05 May 25",
            "📝 Application Form: 01 Mar 25 - 31 Mar 25",
            "📢 Result Announce: 20 Jun 25"
        ],
        linkText: "Apply Now"
    },
    {
        id: 3,
        imgSrc: preimg3,
        title: "GATE 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 03 Feb 25 - 11 Feb 25",
            "📝 Application Form: 24 Aug 24 - 30 Sep 24",
            "📢 Result Announce: 16 Mar 25"
        ],
        inkText: "Apply Now"
    },
    {
        id: 4,
        imgSrc: preimg4,
        title: "CAT 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 24 Nov 25",
            "📝 Application Form: 02 Aug 25 - 15 Sep 25",
            "📢 Result Announce: 15 Jan 26"
        ],
        nkText: "Apply Now"
    },
    {
        id: 5,
        imgSrc: preimg5,
        title: "CLAT 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 01 Dec 24",
            "📝 Application Form: 01 Jul 24 - 03 Nov 24",
            "📢 Result Announce: 25 Dec 24"
        ],
        inkText: "Apply Now"
    },
    {
        id: 6,
        imgSrc: preimg6,
        title: "UPSC CSE 2025 College Predictor",
        rankings: [
            "📅 Prelims: 26 May 25",
            "📝 Application Form: 01 Feb 25 - 21 Feb 25",
            "📢 Result Announce: 01 May 25"
        ],
        linkText: "Apply Now"
    },
    {
        id: 7,
        imgSrc: preimg7,
        title: "BITSAT 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 22 May 25 - 26 May 25",
            "📝 Application Form: 10 Jan 25 - 10 Apr 25",
            "📢 Result Announce: 15 Jun 25"
        ],
        linkText: "Apply Now"
    },
    {
        id: 8,
        imgSrc: preimg8,
        title: "XAT 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 05 Jan 25",
            "📝 Application Form: 15 Jul 24 - 30 Nov 24",
            "📢 Result Announce: 20 Jan 25"
        ],
        nkText: "Apply Now"
    },
    {
        id: 9,
        imgSrc: preimg9,
        title: "SRMJEEE 2025 College Predictor",
        rankings: [
            "📅 Exam Date: 15 Apr 25 - 20 Apr 25",
            "📝 Application Form: 01 Dec 24 - 31 Mar 25",
            "📢 Result Announce: 01 May 25"
        ],
        linkText: "Apply Now"
    },
];


const Collegepre = () => {
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>College Predictor 2025 for JEE Main Top Universities and Exams</h2>
                            <p>Explore the Top Colleges in India for 2025! Whether you're looking for the best engineering, medical, management, or law colleges, our updated rankings help you make an informed choice.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid firstrow" >
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                            <span>  <h6 className='text-secondary mt-1'>India Colleges</h6></span>
                        </div>

                        <p className='toph3 text-start mx-2'>
                            Top Universities & Exams – Get Your Admission Insights
                        </p>

                    </div>
                </div>


                {/* img row  */}
                <div className="row pt-4 justify-content-center">
                    <div className="col-lg-3">
                        <div>
                            <img src={img1} alt="" className='img-fluid mb-3 ' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img2} alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img3} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <h1 className='exploretitle mb-4 '>Entrance Exams In India</h1>
                    {cardData.map((card) => (
                        <div key={card.id} className="col-md-4">
                            <div className="card explore_crd mb-3">

                                <div className=" explore_crd_body bg-light">
                                    <div>
                                        <img className="explore_crd_img w-100" src={card.imgSrc} alt="" />
                                    </div>

                                    <div>
                                        <h5 className="explore_crd_title fs-6 pt-2 fw-bold">{card.title}</h5>
                                    </div>


                                </div>
                                <ul className=" exp_ul_li">
                                    {card.rankings.map((rank, index) => (
                                        <span key={index} className="">
                                            {rank}
                                        </span>
                                    ))}
                                </ul>
                                <div className="p-3">
                                    <Link to="/contact" className="mybtn p-2">
                                        Apply Now
                                        <i className="fa fa-angle-double-right ms-1"></i>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default Collegepre
