import img1 from '../Images/icon1.png';
import img2 from '../Images/icon2.png';
import img3 from '../Images/icon3.png';
// import img4 from '../Images/icon4.png';
import img5 from '../Images/icon5.png';
import img6 from '../Images/icon6.png';
import img7 from '../Images/icon7.png';

export const Carddata = [

    {
        id: "1",
        img: img1,
        title: 'Engineering',
        heading: '8585 Colleges',
        ul1: { name: 'BE/B.Tech', link: '/Engineering' },
        ul2: { name: 'ME/M.Tech', link: '/Engineering' },
        ul3: { name: 'Diploma in Engineering', link: '/Engineering' },

    },
    {
        id: "2",
        img: img2,
        title: 'Management',
        heading: '4542 Colleges',
        ul1: { name: 'MBA/PGDM', link: '/Engineering' },
        ul2: { name: 'BBA/BMS', link: '/Engineering' },
        ul3: { name: 'Executive MBA', link: '/Engineering' },
    },
    {
        id: "4",
        img: img3,
        title: 'Arts',
        heading: '6353 Colleges',
        ul1: { name: 'BA', link: '/Engineering' },
        ul2: { name: 'MA', link: '/Engineering' },
        ul3: { name: 'BFA', link: '/Engineering' },
    },
    {
        id: "5",
        img: img5,
        title: 'Medical',
        heading: '2536 Colleges',
        ul1: { name: 'MBBS', link: '/Engineering' },
        ul2: { name: 'PG Medical', link: '/Engineering' },
        ul3: { name: 'UG Medical', link: '/Engineering' },
    },
    {
        id: "6",
        img: img6,
        title: 'Design',
        heading: '1525 Colleges',
        ul1: { name: 'B.Des', link: '/design/bdes' },
        ul2: { name: 'M.Des', link: '/design/mdes' },
        ul3: { name: 'Web Design', link: '/design/web-design' },
    },
    {
        id: "7",
        img: img7,
        title: 'LAW',
        heading: '8585 Colleges',
        ul1: { name: 'LLB', link: '/law/llb' },
        ul2: { name: 'Diploma in Law', link: '/law/diploma' },
        ul3: { name: 'LLM', link: '/law/llm' },
    }

]

