import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import img1 from '../../Images/1 (1).webp'
import img2 from '../../Images/1 (2).webp'
import img3 from '../../Images/1 (1).webp'
import { Link } from 'react-router-dom'
import Popupform from "../../Popupform/Popupform";
const tableData = [
    {
        coursename: "MBBS (Bachelor of Medicine, Bachelor of Surgery)",
        fees: "10 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB & NEET",
        applicationDate: "1 May - 30 June 2024",
    },
    {
        coursename: "BDS (Bachelor of Dental Surgery)",
        fees: "5 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB & NEET",
        applicationDate: "15 April - 30 May 2024",
    },
    {
        coursename: "BAMS (Bachelor of Ayurvedic Medicine & Surgery)",
        fees: "3 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB & NEET",
        applicationDate: "1 June - 15 July 2024",
    },
    {
        coursename: "BHMS (Bachelor of Homeopathic Medicine & Surgery)",
        fees: "2.5 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB & NEET",
        applicationDate: "10 May - 25 June 2024",
    },
    {
        coursename: "BPT (Bachelor of Physiotherapy)",
        fees: "2 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB",
        applicationDate: "5 May - 20 June 2024",
    },
    {
        coursename: "B.Sc. Nursing",
        fees: "1.5 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB",
        applicationDate: "10 June - 30 July 2024",
    },
    {
        coursename: "Pharm.D (Doctor of Pharmacy)",
        fees: "3.5 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB/PCM",
        applicationDate: "1 June - 15 July 2024",
    },
    {
        coursename: "BMLT (Bachelor in Medical Laboratory Technology)",
        fees: "1.8 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB/PCM",
        applicationDate: "15 May - 30 June 2024",
    },
    {
        coursename: "B.Sc. in Radiology",
        fees: "2.2 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB",
        applicationDate: "5 June - 20 July 2024",
    },
    {
        coursename: "B.Sc. in Biotechnology",
        fees: "1.8 Lakhs (1st Year Fees)",
        eligibility: "10+2 with PCB",
        applicationDate: "1 May - 30 June 2024",
    },
    {
        coursename: "MD (General Medicine)",
        fees: "15 Lakhs (1st Year Fees)",
        eligibility: "MBBS & NEET-PG",
        applicationDate: "1 July - 31 August 2024",
    },
    {
        coursename: "MS (Surgery)",
        fees: "18 Lakhs (1st Year Fees)",
        eligibility: "MBBS & NEET-PG",
        applicationDate: "1 July - 31 August 2024",
    },
];



const Courseprogram = () => {

    const [showPopup, setShowPopup] = useState(false);

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0 m-0">
                        <div className=' background_banner contact_banner'>
                            <h2>Your Education, Your Choice – Discover Top Courses</h2>
                            <p>Choose from 100+ online universities offering top-tier medical programs. Compare institutions based on faculty expertise, curriculum, affordability, and flexibility. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid firstrow" >
                <div className="row firstrow">
                    <div className="col mt-3">
                        <div className='d-flex'>
                            <span><Link to="/"><i className='fa fa-home text-info mx-2 fs-4'></i></Link> </span>
                            <span>  <h6 className='text-secondary mt-1'>Top Courses</h6></span>
                        </div>

                        <p className='toph3 text-start mx-2'>
                            Explore the Best Courses for Your Dream Career
                        </p>

                    </div>
                </div>


                {/* img row  */}
                <div className="row pt-4 justify-content-center">
                    <div className="col-lg-3">
                        <div>
                            <img src={img1} alt="" className='img-fluid mb-3 ' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img2} alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <img src={img3} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>





            {/* table */}


            <div className="container mt-5">
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#e5edff" }}>
                                        <th>Course</th>
                                        <th>Fees</th>
                                        <th>Eligibility</th>
                                        <th>Application Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-3 fw-semibold p-3">
                                                <NavLink to={`/branch/${index}`} className="text-decoration-none text-dark">
                                                    {item.coursename}
                                                </NavLink>
                                            </td>
                                            <td className="text-success">&#8377; {item.fees}</td>
                                            <td>{item.eligibility}</td>
                                            <td>{item.applicationDate}</td>
                                            <td>
                                                <NavLink to="#" className="text-decoration-none" onClick={handleOpenPopup}>
                                                    Apply Now <i className="fa fa-angle-double-right"></i>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Popup Modal */}
                        {showPopup && (
                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <button className="close-btn" onClick={handleClosePopup}>
                                        &times;
                                    </button>
                                    <Popupform />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>








        </div>
    )
}

export default Courseprogram
